import React,{useState, useEffect} from 'react'
import Bar from '../common/bar';
import Side from '../common/side';
import Table from '../components/table';
import { createProject } from '../api';
import { createGroup } from '../api';
import { createFlipBook } from '../api';
import { getProjects } from '../api';
import { getFlipbooks } from '../api';
import { getGroups } from '../api';
import { useNavigate } from 'react-router-dom';

function generateURL() {
  
    const randomString = Math.random().toString(36).substring(2, 6).toUpperCase();
  
    
    const timestamp = Date.now().toString(16).toUpperCase();
  
    
    const randomId = timestamp.substring(0, 8) + '-' + timestamp.substring(8, 12) + '-' + randomString;
  
    return randomId;
}

const DashBoard = () => {

    const [textVisible, setTextVisible] = useState(false);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [selectedItem, setSelectedItem] = useState()
    const [pSelectedItem, setPSelectedItem] = useState()
    const [currentData, setCurrentData] = useState()
    const [projects, setProjects] = useState()
    const [groups, setGroups] = useState()
    const [flipbooks, setFlipbooks] = useState()
    const [reOrder, setReOrder] = useState(false)
    const [refetch, setReFetch] = useState(false)
    const [userDetails, setUserDetails] = useState()
    const [changeOrder, setChangeOrder] = useState(true)
    const [dataLoader, setDataLoader] = useState(true)
    const [types, setTypes] = useState({
        current: "Project",
        nextType: "Group",
        last: "Interactive Book"
    })
    const onCreate = async({currentName}) => {
        setLoading(true)
        
        try{
            console.log("SENDING", currentName)
            let response
            if(types.current==="Project"){
                await createProject({projectName: currentName});
                handleTypeChange("new")
            }else if(types.current==="Group"){
                await createGroup({groupName: currentName, projectId: selectedItem?.id});
                handleTypeChange("new")
            } else if(types.current==="Interactive Book"){
                const url = generateURL()
                const flipBookData = {
                    titleDetails: {
                        title: currentName,
                        url: url
                    }
                }
                
                await createFlipBook({flipBookData: flipBookData, flipBookUrl: url, customFlipBookUrl: url, groupId: selectedItem?.id, flipBookName: currentName});
                handleTypeChange("new")
            }
            
            console.log("RESPONSE", response)
            setLoading(false)
        }catch(error){
            console.error("ERROR", error)
            setLoading(false)
        }
    }
    const toggleText = () => {
      setTextVisible(!textVisible);
    };

    useEffect(()=>{
        if(localStorage.getItem("user")){
            const user = JSON.parse(localStorage.getItem("user"))
            console.log(user)
            setUserDetails(user)
        }else{
            navigate('/')
        }
    }, [])
    const onLogout = () =>{
        localStorage.removeItem("user")
        localStorage.removeItem("access")
        localStorage.removeItem("refresh")
        window.location.reload()
    }
    useEffect(()=>{
        const fetchData = async () => {
          try {
            
            let data
            if(types.current==="Project"&&!projects){
                data = await getProjects(); 
                setProjects(data)
            }else if(types.current==="Group"&&!groups){
                data = await getGroups(); 
                setGroups(data)
                if(!pSelectedItem){
                    setPSelectedItem(selectedItem)
                }
            } else if(types.current==="Interactive Book"&&!flipbooks){
                data = await getFlipbooks();
                setFlipbooks(data) 
            }
            console.log(data)
            if(projects&&types.current==="Project")
                setCurrentData(projects)
            if(groups&&types.current==="Group")
                setCurrentData(groups)
                if(!pSelectedItem){
                    setPSelectedItem(selectedItem)
                }
            if(flipbooks&&types.current==="Interactive Book")
                setCurrentData(flipbooks)
            if(data)
                setCurrentData(data)
            setDataLoader(false)
            
          } catch (error) {
            
            console.error('Error fetching data:', error);
          }
        };
        setDataLoader(true)
        fetchData();
              
        
        
  
    }, [types])
    useEffect(()=>{
        const fetchData = async () => {
          try {
            
            let data
            if(types.current==="Project"){
                data = await getProjects(); 
                setProjects(data)
            }else if(types.current==="Group"){
                data = await getGroups(); 
                setGroups(data)
            } else if(types.current==="Interactive Book"){
                data = await getFlipbooks();
                setFlipbooks(data) 
            }
            console.log(data)
            setCurrentData(data)
            setReFetch(false)
          } catch (error) {
            
            console.error('Error fetching data:', error);
          }
        };
        if(refetch){
            fetchData();
        }       
  
    }, [refetch])
    useEffect(()=>{
        const fetchData = async () => {
          try {
            
            let data = await getProjects(); 
            console.log(data)
            setCurrentData(data)
            
            
          } catch (error) {
            // Handle errors if any
            console.error('Error fetching data:', error);
          }
        };
        fetchData();
              
        
        
  
    }, [])
    console.log(types)
    const handleTypeChange = (esd) =>{
        setCurrentData(undefined)
        if(esd=="new"){
            console.log("Skipping")
            setReFetch(true)
        }else{
            if(types.current==="Project"){
                setTypes({
                    current: "Group",
                    nextType: "Interactive Book",
                    last: "Project"
                })
            } else if(types.current==="Group"){
                setTypes({
                    current: "Interactive Book",
                    nextType: "Project",
                    last: "Group"
                })
            } else if(types.current==="Interactive Book"){
                setTypes({
                    current: "Project",
                    nextType: "Group",
                    last: "Interactive Book"
                })
            }
        }
    }
    const onProjectsClick = () =>{
        setTypes({
            current: "Project",
            nextType: "Group",
            last: "Interactive Book"
        })
        setCurrentData(projects)
    }
    const onGroupsClick = () =>{
        setTypes({
            current: "Group",
            nextType: "Interactive Book",
            last: "Project"
        })
        setSelectedItem(pSelectedItem)
        setCurrentData(groups)
    }
    return (
        <div className='flex bg-gray-100 min-h-screen'>
            <Side textVisible={textVisible} setTextVisible={setTextVisible}/>
            <div className='flex flex-col flex-grow mx-w-mx my-12 mx-6 overflow-auto'>
                <div className='flex justify-between w-full'>
                    <span class="material-symbols-outlined cursor-pointer" onClick={toggleText}>sort</span>
                    <div className='flex justify-between text-[12px] text-[#67748E] font-bold'>
                        <div className='mx-4 flex cursor-pointer'><i className="fa fa-user me-sm-1 mt-1 mr-2" aria-hidden="true"></i><p className=''>{userDetails?.username}</p></div>
                        <div className='mx-4 flex cursor-pointer'><i className="fa fa-edit me-sm-1 mt-1 mr-2" aria-hidden="true"></i><p className=''>Change Password</p></div>
                        <div className='mx-4 flex cursor-pointer' onClick={onLogout}><i className="fa fa-sign-out-alt me-sm mt-1 mr-2" aria-hidden="true"></i><p className=''>Logout</p></div>
                    </div>
                </div>
                {types.current!="Project"&&types.current!="Interactive Book"&&<div className='font-bold mt-5 ml-2 w-full flex text-[12px] cursor-pointer'><span class="material-symbols-outlined -mt-1"> arrow_left </span><p className='hover:underline' onClick={onProjectsClick}>{types.last}s&nbsp;</p> / <p>&nbsp;{types.current}s</p></div>}
                {types.current==="Interactive Book"&&<div className='font-bold w-full mt-5 ml-2 flex text-[12px] cursor-pointer'><span class="material-symbols-outlined -mt-1"> arrow_left </span><p className='hover:underline' onClick={onProjectsClick}>{types.nextType}s&nbsp;</p> / <p className='hover:underline' onClick={onGroupsClick}>&nbsp;{types.last}s</p>/ <p>&nbsp;{types.current}s</p></div>}
                <Bar title={types.current} changeOrder={changeOrder} setChangeOrder={setChangeOrder} reOrder={reOrder} setReOrder={setReOrder} projects={projects} setPSelectedItem={setPSelectedItem} selectedItem= {selectedItem} groups={groups} bt1={"Reorder"} bt2={`Create Interactive Book ${types.current!=="Interactive Book"? types.current: ""}`} onCreate={onCreate} loading={loading} last={types.last} setSelectedItem={setSelectedItem} pSelectedItem={pSelectedItem}/>
                <div className='bg-white h-[80%] shadow-md rounded-[10px] relative overflow-auto'>
                    <Table type={types.current} dataLoader={dataLoader} changeOrder={changeOrder} reOrder={reOrder} groups={groups} projects={projects} selectedItem={selectedItem} setSelectedItem={setSelectedItem} nextType={types.nextType} data={currentData} handleTypeChange={handleTypeChange}/>
                   
                </div>
            </div>

        </div>
    )
}

export default DashBoard;