import axios from "axios";

export const BASE_URL = 'https://interactive-book.ueu-services.com/';
export const MEDIA_URL = 'https://ueu-dev.s3.us-east-2.amazonaws.com/'

const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});
export const login = async ({username, password}) => {
  try {
    const response = await axios.post("https://ueu-dashboards.com/api/v1/login", {username, password});
    return response.data;
  } catch (error) {
    if (error?.status === 500) return error.message;
    return error?.response?.data;
  }
};
export const getFlipbooks = async () => {
    try {
      const response = await api.get("/flipbook");
      return response.data;
    } catch (error) {
      if (error?.status === 500) return error.message;
      return error?.response?.data;
    }
  };
export const getBackgroundImages = async () => {
    try {
      const response = await api.get("/backgroundImages");
      return response.data;
    } catch (error) {
      if (error?.status === 500) return error.message;
      return error?.response?.data;
    }
  };
export const getSounds = async () => {
    try {
      const response = await api.get("/sounds");
      return response.data;
    } catch (error) {
      if (error?.status === 500) return error.message;
      return error?.response?.data;
    }
  };
export const getProjects = async () => {
    try {
      const response = await api.get("/project");
      return response.data;
    } catch (error) {
      if (error?.status === 500) return error.message;
      return error?.response?.data;
    }
  };
export const getGroups = async () => {
    try {
      const response = await api.get("/group");
      return response.data;
    } catch (error) {
      if (error?.status === 500) return error.message;
      return error?.response?.data;
    }
  };
export const fetchFlipbook = async ({flipBookUrl}) => {
    try {
      
      const url = flipBookUrl ? `flipbook?flipBookUrl=${flipBookUrl}` : 'flipbook';
      
      const response = await api.get(url);
  
      return response.data;
    } catch (error) {
     
      console.error('Error fetching flipbooks:', error);
      throw error;
    }
  };
  export const uploadFile = async (file, username) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('username', username);
      console.log("Sending File: ", file)
     
      const fileName = file.name;
  
      const response = await api.post('flipbook/savefile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
  
      return { response: response.data};
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };
  export const uploadFiles = async (formData) => {
    try {
     
      console.log("sending", formData)
      formData.forEach((value, key) => {
        console.log(key, value);
      });
      const response = await api.post('flipbook/savefiles', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
  
      return { response: response.data };
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };
  
export const createFlipBook = async (flipBookData, flipBookUrl, groupId) => {
    try {
      
      console.log("Sending", flipBookData, flipBookUrl, groupId)
      const response = await api.post('flipbook', flipBookData, flipBookUrl, groupId);
      return response
    } catch (error) {
      console.error('Error creating flipbook:', error);
      throw error;
    }
};
export const createProject = async (projectName, groups) => {
    try {
      
      
      const response = await api.post('project', projectName, groups);
      return response
    } catch (error) {
      console.error('Error creating project:', error);
      throw error;
    }
};
export const deleteFlipbook = async (flipbookId) => {
    try {
      
      const response = await api.delete('flipbook', { data: { flipbookId } });
      return response
    } catch (error) {
      console.error('Error deleting flipbook:', error);
      throw error;
    }
};
export const deleteProject = async (projectId) => {
  try {
      
      const response = await api.delete('project', { data: { projectId } });
      return response;
  } catch (error) {
      console.error('Error deleting project:', error);
      throw error;
  }
};

export const deleteGroup = async (groupId) => {
    try {
      
      
      const response = await api.delete('group', { data: { groupId } });
      return response
    } catch (error) {
      console.error('Error deleting group:', error);
      throw error;
    }
};
export const createGroup = async (groupName, flipbooks, projectId) => {
    try {
      
      console.log("Sending", groupName, flipbooks, projectId)
      const response = await api.post('group', groupName, flipbooks, projectId);
      return response
    } catch (error) {
      console.error('Error creating group:', error);
      throw error;
    }
};
export const verifyEmail = async (email) => {
    try {
      
      
      const response = await api.post('verifyEmail/', email);
      return response
    } catch (error) {
      console.error('Error sending mail:', error);
      throw error;
    }
};
export const notifyEmail = async (email, flipBookUrl, flipBookTitle) => {
    try {
      
      
      const response = await api.post('notifyEmail/', email, flipBookUrl, flipBookTitle);
      return response
    } catch (error) {
      console.error('Error sending mail:', error);
      throw error;
    }
};
export const editFlipBook = async (flipBookData, flipBookUrl, flipBookName, customFlipBookUrl) => {
    try {
      
      
      const response = await api.put('flipbook', flipBookData, flipBookUrl, flipBookName, customFlipBookUrl);
      return response
    } catch (error) {
      console.error('Error editing flipbook:', error);
      throw error;
    }
};
export const editProject = async ({projectName, projectId, project2Id, item, copy}) => {
    try {
      
      
      const response = await api.put('project', {projectName, projectId, project2Id, item, copy});
      return response
    } catch (error) {
      console.error('Error editing project:', error);
      throw error;
    }
};
export const editGroup = async ({groupName, groupId, group2Id, item, copy}) => {
    try {
      
      
      const response = await api.put('group', {groupName, groupId, group2Id, item, copy});
      return response
    } catch (error) {
      console.error('Error editing group:', error);
      throw error;
    }
};