
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SavedEditPage from './pages/SavedEditPage';
import ViewPage from './pages/viewPage';
import LoginPage from './pages/login';
import Activate from './pages/activate';
import DashBoard from './pages/dashboard';
import Leads from './pages/leads';
import Analytics from './pages/analytics';
function App() {
  return (
    <div className="App">
     <Router>
            <Routes>
              <Route path="/" element={<LoginPage/>} />
              <Route path="/login" element={<LoginPage/>} />
              <Route path="/activate" element={<Activate/>} />
              
              <Route path="/dashboard" element={<DashBoard/>} />
              <Route path="/leads/:url" element={<Leads/>} />
              <Route path="/analytics/:url" element={<Analytics/>} />
                           
              <Route exact path="edit/*" element={<SavedEditPage/>} />
              <Route path="view/:url" element={<ViewPage/>} />
            </Routes>
    </Router>
    </div>
  );
}

export default App;
