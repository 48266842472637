import React, { useState, useEffect } from 'react';

const ChangeLogo = ({ onClose, imageFiles, setImageFiles }) => {
    const [logoUrl, setLogoUrl] = useState('./add.png');
    const [selectedFile, setSelectedFile] = useState(imageFiles?.logoImage);
    const [logoRedirect, setLogoRedirect] = useState(imageFiles?.logoRedirect)

    const handleChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onload = () => {
                setLogoUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleOkClick = () => {
        
        setImageFiles({
            ...imageFiles,
            logoImage: selectedFile || undefined,
            logoRedirect: logoRedirect || undefined
        });
        
        onClose();
    };

    const handleCancelClick = () => {
        setSelectedFile(null);
        onClose();
    };

    useEffect(() => {
        if (imageFiles?.logoImage instanceof File ) {
            const reader = new FileReader();
            reader.onload = () => {
                setLogoUrl(reader.result);
            };
            reader.readAsDataURL(imageFiles?.logoImage);
        } else {
            setLogoUrl(imageFiles?.logoImage)
        }
    }, [imageFiles]);

    const onDelete = () => {
        setSelectedFile(undefined)
        setLogoUrl("./add.png");
    };

    return (
        <div className='absolute z-50 flex flex-col bg-gray-100 w-[500px] max-h-max top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-[0.5rem] shadow-md items-center'>
            <p className='mt-5 text-[16px] font-bold'>Logo</p>
            <div className='relative flex items-center justify-center w-[420px] h-[100px] mx-8 border border-dashed border-gray-400 p-2'>
                <img src={logoUrl}
                    alt="add-logo"
                    className='object-cover w-content h-full' />
                <button className="absolute top-0 right-0 mt-2 mr-2 rounded-full bg-white text-gray-400 px-2 hover:text-white hover:bg-[#1E78C8] ease-in-out duration-300 font-bold" onClick={() => document.getElementById('ImgInput').click()}><span className="material-symbols-outlined text-[16px] mt-2"> edit </span></button>
                <input
                    type="file"
                    id="ImgInput"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleChange}
                />
                <button className="absolute bottom-0 right-0 mb-2 mr-2 rounded-full bg-white text-gray-400 px-2 hover:text-white hover:bg-red-600 ease-in-out duration-300 font-bold"><span className="material-symbols-outlined text-[16px] mt-2" onClick={onDelete}> delete </span></button>
            </div>
            <p className='ml-2'>We recommend .png with transparent background.</p>
            <div className='text-left mx-8'>
                <p className='ml-2'>Web Address (URL)</p>
                <input type="text" className='w-[80%] border border-gray ml-2 px-2 py-1 rounded-[10px] ' onChange={(e)=>setLogoRedirect(e.target.value)} value={logoRedirect}/>
                <p className='ml-2'>Set a website where your viewers will be redirected when they click on your logo.</p>
            </div>
            <div className='flex w-[30%] my-4'>
                
                <button className='font-bold  p-1.5 w-[50%] mx-1 rounded-[0.5rem] bg-gray-400 text-white cursor-pointer border-2 border-gray-400 hover:bg-white hover:text-gray-600 ease-in-out duration-300' onClick={handleCancelClick}>CANCEL</button>
                <button className='font-bold w-[50%] bg-[#1E78C8] p-1.5  mx-1 rounded-[0.5rem] text-white cursor-pointer border-2 border-[#1E78C8] hover:bg-white hover:text-[#1E78C8] ease-in-out duration-300' onClick={handleOkClick}>OK</button>
            </div>
        </div>
    );
}

export default ChangeLogo;
