import React, { useState, useEffect } from 'react';
import { getBackgroundImages } from '../api';
import { MEDIA_URL } from '../api';

const ImageSelector = ({setBackgroundUrl, selectedImage}) => {
  const [categories, setCategories] = useState([]);
  const [animated, setAnimated] = useState(false)
  const toggleAnimated = () =>{
    setAnimated(!animated)
  }
  console.log("CATEGORIES", categories)
  useEffect(() => {
    const fetchImages = async() => {
        try{
            const response = await getBackgroundImages()
            console.log(response)
            setCategories(JSON.parse(response))
        }catch(error){
            console.log(error)
        }
    }
    fetchImages();
  }, []);

  const handleImageClick = (category, imageName) => {
    const encodedImageName = imageName.replace(/ /g, '%20');
    setBackgroundUrl(`${MEDIA_URL}media/backgroundImages/${category}/${encodedImageName}`);
    console.log('Selected image from category', category, ':', imageName);
  };
  
  

  return (
   <div className='flex flex-col overflow-y-auto'>
      <div className='w-full flex items-center justify-center '>
                <div className='flex items-center my-2 justify-between mx-4 text-sm'>
                   <span className="-ml-4 mt-2">Animated</span>
                       <input
                         type="checkbox"
                         id="animated"
                         checked={animated}
                         onChange={toggleAnimated}
                         className="sr-only"
                       />

                       <label htmlFor="animated" className="cursor-pointer ml-3">
                         <div className="relative w-10 h-4">
                           <div className={`w-12 h-6 rounded-full shadow-inner ${animated ? 'bg-[#1E78C8]' : 'bg-gray-400'}`}></div>
                           <div className={`absolute left-0 top-0 w-6 h-6 bg-white border-2 ${animated ? 'border-[#1E78C8]' : 'border-gray-400'} rounded-full transition-transform duration-300 transform ${animated ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                </div>
      </div>
      {Object.keys(categories).map(category => (
        <div key={category} className='mx-6'>
          <h3>{category}</h3>
          <div className="mx-4 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 gap-4 my-5 overflow-y-auto">
            {categories[category]
              .filter(image => (animated ? image.endsWith('.gif') : !image.endsWith('.gif')))
              .map(image => (
                <div className='w-[120px] h-[80px]' style={{
                  cursor: 'pointer',
                 
                  border: selectedImage === `${MEDIA_URL}media/backgroundImages/${category}/${encodeURIComponent(image).replace(/ /g, '%20')}` ? '2px solid blue' : 'none',
                  filter: selectedImage === `${MEDIA_URL}media/backgroundImages/${category}/${encodeURIComponent(image).replace(/ /g, '%20')}` ? 'brightness(70%)' : 'none'
                }}>
                  <img
                  key={image}
                  src={`${MEDIA_URL}media/backgroundImages/${category}/${image}`}
                  alt={image}
                  onClick={() => handleImageClick(category, image)}
                  
                  className='object-cover w-full h-full'
                />
                </div>

              ))}
          </div>
        </div>
      ))}

    </div>
  );
};

export default ImageSelector;
