import React, { useRef, useEffect, useState } from 'react';
import { BASE_URL } from '../api';

const PdfViewer = ({ pdfUrl: initialPdfUrl, height, backgroundImage, settings, hideMenu, controls }) => {
  const iframeRef = useRef(null);

  // Use a state variable to track the dynamic PDF URL
  const [pdfUrl, setPdfUrl] = React.useState(initialPdfUrl);

  useEffect(() => {
    // Construct the iframe URL with query string parameter
    const iframeSrc = new URL(`${BASE_URL}render`);

    iframeSrc.searchParams.set('pdfUrl', pdfUrl);

    iframeSrc.searchParams.set('hideMenu', hideMenu);

    // Add settings to the query parameters
    for (const key in settings) {
      iframeSrc.searchParams.set(key, settings[key]);
    }
    for (const key in controls) {
      iframeSrc.searchParams.set(key, controls[key]);
    }
    // Update the iframe's source
    iframeRef.current.src = iframeSrc.toString();
  }, [pdfUrl, hideMenu]);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    const iframe = document.querySelector('iframe');

    if (isFullScreen) {
      iframe.style.backgroundImage = `url(${backgroundImage})`;
      iframe.style.backgroundSize = 'cover';
    } else {
      iframe.style.backgroundImage = 'none';
    }
  }, [isFullScreen]);

  return (
    <iframe
      src={`${BASE_URL}render`} // Initial source, will be updated in useEffect
      width="100%"
      height={height}
      ref={iframeRef}
      className='-mt-3 w-[98%] h-[98%]'
      allowFullScreen={true}
    />
  );
};

export default PdfViewer;