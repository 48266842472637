import React, { useState, useEffect } from 'react';
import ImageSelector from '../common/backgroundImages';
const ChangeBackground =({onClose, imageFiles, setImageFiles}) => {
    const [backgroundUrl, setBackgroundUrl] = useState('');

    const handleOkClick = () =>{
      setImageFiles({
        ...imageFiles,
        backgroundImage: backgroundUrl
      });
      onClose()
    }
      
    const onClosing = () =>{
        
        setImageFiles({
            ...imageFiles,
            
        })
        setBackgroundUrl("")
        onClose()
    }
    useEffect(()=>{
        if(imageFiles?.backgroundImage){
          setBackgroundUrl(imageFiles?.backgroundImage)
        }
    }, [imageFiles])


    return (
        <div className='absolute z-50 flex flex-col bg-gray-100 w-[800px] h-[500px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-[0.5rem] shadow-md items-center'>
            <p className='mt-5 text-[16px] font-bold'>Background</p>
            
                
               <ImageSelector setBackgroundUrl={setBackgroundUrl} selectedImage={backgroundUrl}/>
           
            
            <div className='flex w-[30%] my-4'><button className='font-bold w-[50%] p-1.5 rounded-[0.5rem] mx-1 bg-gray-400 text-white cursor-pointer border-2 border-gray-400 hover:bg-white hover:text-gray-600 ease-in-out duration-300' onClick={onClosing}>CANCEL</button>
            <button className='font-bold w-[50%] bg-[#1E78C8] text-white p-1.5 rounded-[0.5rem] mx-1 cursor-pointer border-2 border-[#1E78C8] hover:bg-white hover:text-[#1E78C8] ease-in-out duration-300' onClick={handleOkClick}>OK</button>
            </div>
        </div>
    );
}

export default ChangeBackground;