import React, { useState, useEffect } from 'react';

const CustomBackground =({onClose, imageFiles, setImageFiles}) => {
    const [backgroundUrl, setBackgroundUrl] = useState('./add.png');
    const [customColor, setCC]= useState(false)
    const [backgroundImage, setBackgroundImage] = useState(imageFiles?.backgroundImage)
    const [backgroundColor, setBackgroundColor] = useState(imageFiles?.backgroundColor)

    const toggleCustomColor = (value) =>{
        setCC(value)
        
        if(value===false){
          setBackgroundColor(undefined)
        } else if(value == true){
          setBackgroundImage(undefined)
        }
    }
    const handleChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
         
          console.log('Selected file:', selectedFile);
          setBackgroundImage(selectedFile)
          const reader = new FileReader();
          reader.onload = () => {
            setBackgroundUrl(reader.result);
          };
          reader.readAsDataURL(selectedFile);
        }
    };
    const onClosing = () =>{
        
        setImageFiles({
            ...imageFiles,
           
        })
        setBackgroundUrl("https://th.bing.com/th/id/R.194fa56cc2de1cd729e0b654b4d3b6ba?rik=CJbALO2mBWIYTg&pid=ImgRaw&r=0")
        onClose()
    }
    useEffect(() =>{
        console.log("checking files")
        if(imageFiles?.backgroundColor){
          setCC(true)
          
        }       
        if(imageFiles?.backgroundImage instanceof File){
            const reader = new FileReader();
            reader.onload = () => {

              setBackgroundUrl(reader.result);
              
            };
            reader.readAsDataURL(imageFiles?.backgroundImage);
        } else if (typeof imageFiles?.backgroundImage === 'string' && /^https?:\/\/\S+$/.test(imageFiles?.backgroundImage)) {
            setBackgroundUrl(imageFiles?.backgroundImage);
            
        } else {
            setBackgroundUrl(imageFiles?.backgroundImage);
        }
    }, [imageFiles])
    const onDelete = () => {
        setBackgroundImage(undefined)
      
        setBackgroundUrl("./add.png")
    }
 
    const handleOkClick = () => {
      const updatedImageFiles = {
        ...imageFiles,
        backgroundImage: backgroundImage || undefined,
        backgroundColor: backgroundColor || undefined,
      };
    
      setImageFiles(updatedImageFiles);
      onClose();
    };
    
    
    console.log("IMAGE FILES", imageFiles, "Background Color", backgroundColor)
    return (
        <div className='absolute z-50 flex flex-col bg-gray-100 w-[500px] max-h-max top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-[0.5rem] shadow-md items-center text-left'>
              <div className='flex ml-8 mt-8 w-[90%] text-left'>
                     <input
                         type="checkbox"
                         id="customColor"
                         checked={customColor}
                         onChange={(e)=> toggleCustomColor(!customColor)}
                         className="sr-only"
                       />

                       <label htmlFor="customColor" className="cursor-pointer">
                         <div className="relative w-10 h-4">
                           <div className={`w-12 h-6 rounded-full shadow-inner ${customColor ? 'bg-[#1E78C8]' : 'bg-gray-400'}`}></div>
                           <div className={`absolute left-0 top-0 w-6 h-6 bg-white border-2 ${customColor ? 'border-[#1E78C8]' : 'border-gray-400'} rounded-full transition-transform duration-300 transform ${customColor ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                   <p className='ml-6 text-[14px]'>Use color as background</p>
              </div>
             {customColor?(
                <div className='flex flex-col mx-8 mt-8 w-[90%] text-left'>
                     <p className='my-3 text-[16px] font-bold'>Backgroud Color</p>
                     <input type="color" name="title-color" id="" className='w-[30%] rounded-[05.rem] h-[4.5rem]' value={backgroundColor} onChange={(e)=> setBackgroundColor(e.target.value)}/>
                </div>
             ):(
                <div className='mx-8'>
                <p className='my-3 text-[16px] font-bold'>Backgroud Image</p>
                   <div className='relative flex items-center justify-center w-[420px] h-[100px] border border-dashed border-gray-400 p-2'>
                     <img src={backgroundUrl} 
                     alt="add-background"

                     className='object-cover w-content h-full'/>
                     <button className="absolute top-0 right-0 mt-2 mr-2 rounded-full bg-white text-gray-400 px-2 hover:text-white hover:bg-[#1E78C8] ease-in-out duration-300 font-bold" onClick={() => document.getElementById('ImgInput').click()}><span class="material-symbols-outlined text-[16px] mt-2"> edit </span></button>
                     <input
                               type="file"
                               id="ImgInput"
                               accept="image/*"
                               style={{ display: 'none' }}
                               onChange={handleChange}
                     />
                     <button className="absolute bottom-0 right-0 mb-2 mr-2 rounded-full bg-white text-gray-400 px-2 hover:text-white hover:bg-red-600 ease-in-out duration-300 font-bold"><span class="material-symbols-outlined text-[16px] mt-2" onClick={onDelete}> delete </span></button>
                   </div>
                </div>
           
             )}
            <div className='flex w-[30%] my-4'>
              <button className='font-bold w-[50%] p-1.5  mx-1 rounded-[0.5rem] bg-gray-400 text-white cursor-pointer border-2 border-gray-400 hover:bg-white hover:text-gray-600 ease-in-out duration-300' onClick={onClosing}>CANCEL</button>
              <button className='font-bold w-[50%] bg-[#1E78C8] p-1.5  mx-1 rounded-[0.5rem] text-white cursor-pointer border-2 border-[#1E78C8] hover:bg-white hover:text-[#1E78C8] ease-in-out duration-300' onClick={handleOkClick}>OK</button>  
            </div>
        </div>
    );
}

export default CustomBackground;