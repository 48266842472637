import React from 'react';
import Spinner from "./spinner"
import { useNavigate } from 'react-router-dom';
import Row from './renderRow';
const Table = ({type, nextType, data, dataLoader, handleTypeChange, setSelectedItem, selectedItem, groups, projects, reOrder, changeOrder}) => {
    const navigate = useNavigate()
    console.log("DATA", data, selectedItem)
    
    return (
        <div className='w-full h-full flex flex-col text-left m-4 overflow-auto'>
            <>
                {data&&!dataLoader?(
                    <>
                    {data?.length>0 ?(
                       
                            
                           <Row 
                                handleTypeChange={handleTypeChange} 
                                selectedItem={selectedItem} 
                                data={data} 
                                type={type} 
                                nextType={nextType}
                                setSelectedItem={setSelectedItem}
                                groups={groups}
                                projects={projects}
                                reOrder={reOrder}
                                changeOrder = {changeOrder}
                                />
                            
                       
                    ):(
                        <div className='grid w-full h-full text-center'>
                            <p>No {type}s found!</p>
                        </div>
                    )}
                    </>
                ):(
                   <Spinner/>    
                )}
            </>
           
        </div>
    );
}

export default Table