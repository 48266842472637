import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css'; 
import { useParams } from 'react-router-dom';
import { fetchFlipbook } from '../api';
import Header from '../common/headerClassic';

// Dummy data for testing
const dummyAnalyticsData = [
  {
      title: 'Website Visits',
      type: 'line',
      data: {
          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
          datasets: [
              {
                  label: 'Website Visits',
                  data: [65, 59, 80, 81, 56, 55, 40],
                  fill: false,
                  borderColor: 'rgb(75, 192, 192)',
                  tension: 0.1
              }
          ]
      },
      options: {}
  },
  {
      title: 'Visits by Device Type',
      type: 'bar',
      data: {
          labels: ['Desktop', 'Mobile', 'Tablet'],
          datasets: [
              {
                  label: 'Visits by Device Type',
                  data: [300, 450, 200],
                  backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)']
              }
          ]
      },
      options: {}
  },
  {
    title: 'Visits by Country',
    type: 'bar', // Change chart type to 'bar'
    data: {
        labels: ['USA', 'UK', 'Canada', 'Australia', 'Germany'],
        datasets: [
            {
                label: 'Visits by Country',
                data: [100, 200, 150, 300, 250],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(255, 205, 86, 0.6)',
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(153, 102, 255, 0.6)'
                ]
            }
        ]
    },
    options: {}
},
{
  title: 'Visits by City',
  type: 'bar',
  data: {
      labels: ['New York', 'London', 'Toronto', 'Sydney', 'Berlin'],
      datasets: [
          {
              label: 'Visits by City',
              data: [50, 80, 60, 100, 70],
              backgroundColor: 'rgba(255, 99, 132, 0.6)'
          }
      ]
  },
  options: {}
},
{
    title: 'Visits by Browser',
    type: 'bar', // Change chart type to 'bar'
    data: {
        labels: ['Chrome', 'Firefox', 'Safari', 'Edge', 'Opera'],
        datasets: [
            {
                label: 'Visits by Browser',
                data: [300, 200, 150, 100, 50],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(255, 205, 86, 0.6)',
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(153, 102, 255, 0.6)'
                ]
            }
        ]
    },
    options: {}
},
{
    title: 'Visits by Operating System',
    type: 'bar', // Change chart type to 'bar'
    data: {
        labels: ['Windows', 'MacOS', 'Linux', 'iOS', 'Android'],
        datasets: [
            {
                label: 'Visits by Operating System',
                data: [400, 300, 100, 200, 250],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(255, 205, 86, 0.6)',
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(153, 102, 255, 0.6)'
                ]
            }
        ]
    },
    options: {}
},
 
 
];


const Analytics = (props) => {
    const { url } = useParams()
    const [flipBookData, setFlipbookData] = useState()
    const [analytics, setAnalytics] = useState(dummyAnalyticsData); // Set dummy data

    const chartRefs = useRef([]);

    useEffect(() => {
        // Render charts on canvas elements
        renderCharts();

        return () => {
            // Cleanup function to destroy charts when component unmounts
            chartRefs.current.forEach(chartRef => {
                if (chartRef.chart) {
                    chartRef.chart.destroy();
                }
            });
        };
    }, []);

    const renderCharts = () => {
        analytics.forEach((data, index) => {
            const canvasId = `chartCanvas${index}`;
            const canvas = document.getElementById(canvasId);

            if (!canvas) return;

            const ctx = canvas.getContext('2d');
            const chart = new Chart(ctx, {
                type: data.type, // Chart type (e.g., 'line', 'bar', 'pie', etc.)
                data: data.data, // Chart data
                options: data.options // Chart options
            });

            chartRefs.current.push({ id: canvasId, chart });
        });
    };
    useEffect(()=>{
        const fetchData = async () => {
          try {         
            const data = await fetchFlipbook({ flipBookUrl: url });
            console.log(data)
            const {flipBookData} = data
            setFlipbookData(flipBookData)
            const {imageFiles, titleDetails, pdfLink, privacy, buttons, leadCapture, analytics} = flipBookData  
            //setAnalytics(analytics)
          } catch (error) {
            console.error('Error fetching flipbooks:', error);
          }
        };
        fetchData();
              
        
        
  
    }, [url])
    const [selectedRange, setSelectedRange] = useState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      }
    ]);
  
    // Function to handle date range change
    const handleDateRangeChange = (ranges) => {
      setSelectedRange([ranges.selection]);
    };
  
   
    return (
      <div className="container mx-auto p-4">
        <Header name={`ANALTICS of ${flipBookData?.titleDetails?.title}`}/>
      <div className="flex justify-center mb-8 border-b">
        <div className="flex items-center">
          
          <DateRangePicker
            onChange={handleDateRangeChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={selectedRange}
            direction="horizontal"
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
            {analytics.map((data, index) => (
                    <div key={index}>
                        <h2 className="text-xl font-bold mb-4">{data.title}</h2>
                        <canvas id={`chartCanvas${index}`}></canvas>
                    </div>
                ))}
      </div>
    </div>
    );
}

export default Analytics;