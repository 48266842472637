import React, { useEffect, useState } from 'react';
import { editProject } from '../api';
import { editGroup } from '../api';
import { editFlipBook } from '../api';
import Loader from '../common/loader';
const RenameProject = ({onClose, onCreate, currentType, item}) => {
    const [newPName, setNewPName] = useState()
    const [error, setError] = useState()
    const [loading, setLoading] = useState()
    const onOk = async() => {
        if (newPName&&newPName?.trim() !== '') {
            try{
                setLoading(true)
                if(currentType==='Project'){
                
                  const response = await editProject({projectName: newPName, projectId: item.id})
                  console.log(response)
                }if(currentType==='Group'){
                  const response = await editGroup({groupName: newPName, groupId: item.id})
                  console.log(response)
                }if(currentType==='Interactive Book'){
                  const flipBookData ={
                     ...item.flipBookData,
                     titleDetails:{
                        ...item.flipBookData.titleDetails,
                        title: newPName
                     }
                  }
                  const response = await editFlipBook({flipBookData: flipBookData, flipBookUrl: item.flipBookUrl})
                  console.log(response)
                }
                setLoading(false)
            }catch(error){

            }
            onClose();
        } else {
            setError(`Please enter a valid ${currentType.toLowerCase()} name`);
        }
    }
    
    return (
        <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 flex flex-col bg-gray-100 w-[500px] h-[250px] rounded-[10px] shadow-md items-center'>           
            <div className='m-4 mt-3 w-[80%]'>
            </div>
            <div className='m-4 w-[80%]'>
                <p className='text-base font-bold'>Rename {currentType === "Group" ? item.groupName : (currentType === "Interactive Book" ? item.flipBookData.titleDetails.title : item.projectName)}</p>

                <input type="text" className='w-[80%] border border-gray-400 my-2 px-2 py-2 rounded-[10px] ' onChange={(e)=>{setNewPName(e.target.value); setError('')}} value={newPName}/>
                {error&&<p className='text-[12px] text-red-600'>{error}</p>}
                <p className='text-[12px] text-gray-400'>Ensure a unique {currentType} name to avoid redundency.</p>
            </div>
            <div className='flex w-[80%] my-4 h-10 text-sm'>
                <button className='font-bold w-[50%] mx-2 rounded-[10px] bg-gray-400 text-white cursor-pointer border-2 border-gray-400 hover:bg-white hover:text-gray-600 ease-in-out duration-300' onClick={onClose}>CANCEL</button>
                <button className='font-bold w-[50%] bg-[#1E78C8] mx-2 text-white rounded-[10px] cursor-pointer border-2 border-[#1E78C8] hover:bg-white hover:text-[#1E78C8] ease-in-out duration-300' onClick={onOk}>{loading?<Loader/>: 'SAVE'}</button>

            </div> 
        </div>
    );
}

export default RenameProject;