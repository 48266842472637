import React, { useState } from 'react';
import { editProject } from '../api';
import { editGroup } from '../api';
import Loader from '../common/loader';

const MoveProject = ({onClose, onCreate, currentType, item, groups, projects, selectedItem}) => {
    const [newPName, setNewPName] = useState()
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    const [copy, setCopy] = useState(false);

    const handleCopyChange = (e) => {
      setCopy(e.target.checked);
    };
    const onOk = () => {
        if (newPName&&newPName?.trim() !== '') {
            
            onCreate({currentName: newPName})
            onClose();
        } else {
            setError(`Please enter a valid ${currentType.toLowerCase()} name`);
        }
    }
    const [selectedProject, setSelectedProject] = useState(projects&&projects[0])
    const [selectedGroup, setSelectedGroup] = useState()
    const onConfirm = async() =>{
        setLoading(true)
        if(selectedGroup&&selectedProject&&currentType==="Interactive Book"){
            try{
                const response = await editGroup({groupId: selectedItem.id, group2Id: selectedGroup.id, item: item, copy: copy, groupName: selectedItem.groupName})
                console.log("RESPONSE", response)
                setLoading(false)
                onClose()
                window.location.reload()
            }catch(error){
                console.error("ERROR", error)
                setLoading(false)
            }
        }
        if(selectedProject&&currentType==="Group"){
            try{
                const response = await editProject({projectId: selectedItem.id, project2Id: selectedProject.id, item: item, copy: copy, projectName: selectedItem.projectName})
                console.log("RESPONSE", response)
                setLoading(false)
                onClose()
                window.location.reload()
            }catch(error){
                console.error("ERROR", error)
                setLoading(false)
            }
        }
    }
    
    return (
        <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 flex flex-col bg-gray-100 w-[500px] h-max rounded-[0.5rem] shadow-md items-center'>           
            <div className='m-4 mt-3 w-[80%]'>
            </div>
            <div className='m-4 w-[80%]'>
                <p className='text-base font-bold'>Move {currentType === "Group" ? item.groupName : (currentType === "Interactive Book" ? item.flipBookData.titleDetails.title : item.projectName)} to Project</p>
                {currentType}
                <select className='border-2 border-gray-400 rounded-[5px] px-2 w-full text-left py-1.5 mt-2' defaultValue={item?.id} onChange={(e) => setSelectedProject(projects.find(project => project.id === e.target.value))}>{projects?.map((item)=><option value={item.id}>{item?.projectName}</option>)}</select>
                {currentType==="Interactive Book" && (
                    <>
                        <p className='text-base font-bold'>Move {currentType === "Group" ? item.groupName : (currentType === "Interactive Book" ? item.flipBookData.titleDetails.title : item.projectName)} to Group</p>

                        <select className='border-2 border-gray-400 rounded-[5px] px-2 w-full text-left py-1.5 mt-2' defaultValue={item?.id} onChange={(e) => setSelectedGroup(groups.find(group => group.id === e.target.value))}>{groups.filter(group=> group?.projectId === selectedProject?.id)?.map((item)=><option value={item.id}>{item?.groupName}</option>)}</select>
                    </>
                )}
                {error&&<p className='text-[12px] text-red-600'>{error}</p>}
                <div className='flex ml-1 mt-2'>
                    <input 
                       type="checkbox" 
                       name="copy" 
                       id="copy" 
                       onChange={handleCopyChange} 
                       checked={copy} 
                     /> 
                    <p className='text-[12px] text-gray-400 ml-2'>
                      Keep a copy of {currentType === "Group" ? item.groupName : (currentType === "Interactive Book" ? item.flipBookData.titleDetails.title : item.projectName)} in current Project.
                    </p>               
                </div>
            </div>
            <div className='flex w-[80%] my-4 h-10 text-sm'>

                <button className='font-bold w-[50%] mx-2 rounded-[0.5rem] bg-gray-400 text-white cursor-pointer border-2 border-gray-400 hover:bg-white hover:text-gray-600 ease-in-out duration-300' onClick={onClose}>CANCEL</button>
                <button className='font-bold w-[50%] bg-[#1E78C8] mx-2 text-white rounded-[0.5rem] cursor-pointer border-2 border-[#1E78C8] hover:bg-white hover:text-[#1E78C8] ease-in-out duration-300' onClick={onConfirm}>{loading?<Loader/>: 'MOVE'}</button>
                
                </div> 
        </div>
    );
}

export default MoveProject;