import React, { useState } from 'react'
import { deleteFlipbook } from '../api';
import { deleteGroup } from '../api';
import { deleteProject } from '../api';
import Loader from '../common/loader';
const ConfirmDelete = ({ onClose,  currentType, item }) => {

    const [loading, setLoading] = useState(false)
    const onConfirm = async() => {
        try{
          setLoading(true)
          if(currentType==='Project'){

            const response = await deleteProject(item.id)
            console.log(response)
          }if(currentType==='Group'){
            const response = await deleteGroup(item.id)
            console.log(response)
          }if(currentType==='Interactive Book'){
            const response = await deleteFlipbook(item.id)
            console.log(response)
          }
          setLoading(false)
          onClose()
          window.location.reload()
        }catch(error){
          setLoading(false)
          console.error("ERROR", error)
        }
    }

    return (
      <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 flex flex-col bg-gray-100 w-[500px] h-[250px] rounded-[10px] shadow-md items-center'>
        <div className='m-4 mt-6 w-[80%]'>
          <p className='text-base font-bold'>Delete Confirmation</p>
        </div>
        <div className='mx-4 my-8 text-sm'>
          <p className=''>Are you sure you want to delete {currentType === "Group" ? item.groupName : (currentType === "Interactive Book" ? item.flipBookData.titleDetails.title : item.projectName)} from {currentType}s?</p>
        </div>
        <div className='flex w-[80%] my-8 h-10 text-sm'>
          
          <button className='font-bold w-[50%] mx-2 rounded-[10px] bg-gray-400 text-white cursor-pointer border-2 border-gray-400 hover:bg-white hover:text-gray-600 ease-in-out duration-300' onClick={onClose}>CANCEL</button>
          <button className='font-bold w-[50%] bg-white mx-2 text-red-600 rounded-[10px] cursor-pointer border-2 border-red-600 hover:bg-red-600 hover:text-white ease-in-out duration-300' onClick={onConfirm}>{loading? <Loader additionalClasses={'bg-white text-red-600 hover:bg-red-600 hover:text-white ease-in-out duration-300'}/>:"DELETE"}</button>
        </div>
      </div>
    );
  }
  
export default ConfirmDelete;
  