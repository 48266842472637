import React from 'react';

const Header = ({name}) => {
    
    return (
        <div className='flex justify-between relative mb-4 border-b'>
            <a href='/dashboard'>
                <img src="/logo.png" alt="Logo" className="h-10 w-auto mx-4 mb-2" />
            </a>
            <p className={`mt-4 font-bold text-[12px] text-left `}>{name}</p>
           
        </div>
    );
}

export default Header;