import React, { useEffect, useState } from 'react';
import ExcelTable from '../components/excelTable';
import { useParams } from 'react-router-dom';
import { fetchFlipbook } from '../api';
import Header from '../common/headerClassic';

const Leads = (props) => {
    const { url } = useParams()
    const [leads, setLeads] = useState()
    const [flipBookData, setFlipbookData] = useState()
    useEffect(()=>{
        const fetchData = async () => {
          try {         
            const data = await fetchFlipbook({ flipBookUrl: url });
            console.log(data)
            const {flipBookData} = data
            setFlipbookData(flipBookData)
            const {imageFiles, titleDetails, pdfLink, privacy, buttons, leadCapture, leads} = flipBookData  
            setLeads(leads)
          } catch (error) {
            console.error('Error fetching flipbooks:', error);
          }
        };
        fetchData();
              
        
        
  
    }, [url])

    return (
        <div className='container mx-auto p-4'>
            <Header name={`Leads of ${flipBookData?.titleDetails?.title}`}/>
            <ExcelTable data={leads}/>
        </div>
    );
}

export default Leads;