import React, { useEffect, useState } from 'react';

const SendEmailsTo = ({onClose, settings, setSettings}) => {

    const [error, setError] = useState()

    return (
        <div className='fixed top-1/2 left-1/2 transform overflow-auto -translate-x-1/2 -translate-y-1/2 z-50 flex flex-col bg-gray-100 w-[500px] h-[250px] rounded-[10px] shadow-md items-center'>           
            <div className='mx-4 mt-3 w-[80%]'>
              <p className='text-base font-bold'>Set emails to send notifications</p>
            </div>
            <div className='mx-4 w-[80%]'>
                
                <textarea rows={3} placeholder='Make sure to seperate them with ; ' className='w-[80%] border border-gray-400 my-2 px-2 py-1 rounded-[10px] ' onChange={(e)=>setSettings({...settings, sendEmailsTo: e.target.value})} value={settings?.sendEmailsTo}/>
                {error&&<p className='text-[12px] text-red-600'>{error}</p>}
                <p className='text-[12px] text-gray-400'>Seperate multiple emails using semicolon (;) <br/> Upto 5 emails are allowed</p>
            </div>
            <div className='flex w-[80%] my-4 h-10 text-sm'>
            <button className='font-bold w-[50%] mx-2 rounded-[10px] bg-gray-400 text-white cursor-pointer border-2 border-gray-400 hover:bg-white hover:text-gray-600 ease-in-out duration-300' onClick={onClose}>CANCEL</button>
                <button className='font-bold w-[50%] bg-[#1E78C8] mx-2 text-white rounded-[10px] cursor-pointer border-2 border-[#1E78C8] hover:bg-white hover:text-[#1E78C8] ease-in-out duration-300' onClick={onClose}>OK</button>

            </div> 
        </div>
    );
}

export default SendEmailsTo;