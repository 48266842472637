import React, { useState } from 'react';

const Activate = () => {
 

  return (
    <div className="min-h-screen flex items-center justify-center bg-white bg-center">
      <div className="bg-white p-8 rounded-lg w-full lg:w-[40%] flex flex-col justify-between">
        <div className="flex items-center justify-center mb-20 -mt-8">
            <img src="/logo.png" alt="Logo" className="h-12 w-auto" />
            <h2 className="ml-2 text-[18px] lg:text-xl text-[#1E78C8] font-semibold">Urban Entrepreneur Universe</h2>
        </div>
        <div className='mx-12 lg:mx-auto text-left'>
          
          <p className="text-lg text-[#334564] mb-4 font-bold">Verify your Identity</p>
          <p className="text-sm text-[#334564] mb-4">Please Enter Interactive Book Generated Activation Code From UEU-Dashboards.</p>
          <form className='text-left'> 
            <div className="mb-4">
             
              <input type="activationCode" id="activationCode" name="activationCode" placeholder='Activation Code' className="mt-1 p-2 block w-full rounded-md border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
            </div>
            
            <div className='w-full'>

                <button type="submit" className="w-full py-2 px-4 bg-[#1E78C8] font-bold text-white rounded-md border-2 border-[#1E78C8] hover:bg-white hover:text-[#1E78C8] transition duration-300 ease-in-out">ACTIVATE</button>
            </div>
          </form>
          <div className='w-full mt-4 text-center'>
            <p className="text-sm text-[#334564]">Don’t Have an Activation Code? <a href="https://ueu-dashboards.com/accounts/login/basic-login/" target='_blank' className="font-medium text-[#1E78C8] hover:text-[#1E78C8]">Get it From UEU-Dashboards</a></p>
          </div>
        </div>
      </div>
      <div className='min-h-screen relative bg-cover bg-center w-[60%] text-white hidden lg:block' style={{backgroundImage: `url('https://s3-alpha-sig.figma.com/img/2183/2d8d/fbefdf61276fb76590eb7ddaf2bf73e7?Expires=1711324800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=jz0GQiR5v04zet9SvBKVGr6mVmXdI96VUr5eZWy3N-wPGha4dtjYJZ821dYLrcbXPmuPvYh-mnf0JFsMnsGlNcCBnojVn-sz3toiCLywEYj4csTCOYdYbA9M-Dluhe-tE9N1EVZMPZ2bH6GHEuGtNjABkYVF5CaXXE0KxAuBKRQ44QTT3bDeLfQbOzv1tpOlkgP6tmgKNfJ5ImPjBrx~NUWeTs2976BSrJkw8MLgOuU4LJGjc6PoyCMOS0j6lIEmbYZmhHV2e67ZYW5cL9~KYiFPkqUBoTr6gaUoVvOnxAM50v4Xx8II6z9cmy-fagl3PFePWtgyzF8tunClhL9LHg__')`}}>
      <div className='absolute left-80 top-40 bg-[#1E78C8] w-40 h-40 filter blur-[80px]'></div>
      <div className='absolute left-[150px] top-[70%] bg-[#1E78C8] w-60 h-60 filter blur-[90px]'></div>
      <div className='absolute left-[450px] top-[70%] bg-[#1E78C8] w-60 h-60 filter blur-[90px]'></div>
        <div className='absolute top-[75%] w-full poppins'>
          
          <h2 className='font-[700] text-[28px]'>Unleash your teaching skills</h2>
          <p>Effortlessly organize your teaching materials with a seamless experience</p>
        </div>
      </div>
    </div>
  );
}

export default Activate;
