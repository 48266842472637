import React from 'react';

const ExcelTable = ({ data }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full border-collapse border border-gray-300">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2"></th>
            <th className="border border-gray-300 px-4 py-2">Name</th>
            <th className="border border-gray-300 px-4 py-2">Email</th>
            <th className="border border-gray-300 px-4 py-2">Phone</th>
            <th className="border border-gray-300 px-4 py-2">Company</th>
            <th className="border border-gray-300 px-4 py-2">Custom Field 1</th>
            <th className="border border-gray-300 px-4 py-2">Custom Field 2</th>
          </tr>
        </thead>
        <tbody>
          {data
            ?.filter(obj => obj && Object.keys(obj).length !== 0)
            ?.map((row, index) => (
              <tr key={index}>
                <td className="border border-gray-300 px-4 py-2">{index + 1}</td>
                <td className="border border-gray-300 px-4 py-2">{row?.name}</td>
                <td className="border border-gray-300 px-4 py-2">{row?.email}</td>
                <td className="border border-gray-300 px-4 py-2">{row?.phone}</td>
                <td className="border border-gray-300 px-4 py-2">{row?.company}</td>
                <td className="border border-gray-300 px-4 py-2">{row?.customField1}</td>
                <td className="border border-gray-300 px-4 py-2">{row?.customField2}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExcelTable;
