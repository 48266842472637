import React from 'react';

const Side = ({textVisible, setTextVisible}) => {
    
    return (
        <div className={`flex flex-col mx-w-mx my-10 ml-5 bg-white p-4 shadow-md rounded-[10px]  ${textVisible?"hidden lg:relative absolute left-0":"absolute lg:relative left-0"} lg:block`} onMouseEnter={(e)=>setTextVisible(true)} onMouseLeave={(e)=>setTextVisible(false)}>
          <p className='font-bold absolute right-2 top-1 text-gray-300 lg:hidden cursor-pointer' onClick={(e)=>setTextVisible(!textVisible)}>X</p>
          <p className='flex cursor-pointer mb-8'>
            <img src="/logo.png" alt="Logo" className="h-10 w-auto mr-2" />
            <h2 className={`font-semibold mt-2 ${textVisible ? 'transition-opacity duration-300 opacity-100' : 'transition-opacity duration-300 opacity-0 hidden'}`}>UEU DashBoard</h2>
          </p>
          <p className='flex cursor-pointer'>
            <span className="material-symbols-outlined bg-white h-8 w-8 p-1 text-center rounded-[5px] shadow-md"> deployed_code </span>
            <span className={`transition-opacity ml-2 text-sm font-gray-400 mt-2 duration-300 ${textVisible ? 'opacity-100' : 'opacity-0 hidden'}`}>Interactive Book Project</span>
          </p>
        
        </div>
    );
}

export default Side;