import React, { useEffect, useState } from 'react';
import ChangeLogo from './changeLogo';
import ChangeBackground from './changeBackground';
import CustomBackground from './customBackground';
import ChangeFavicon from './changeFavicon';
import Loader from '../common/loader';
import AddPrivacyPolicy from './addPrivacyPolicy';
import AllowedEmails from './addEmails';
import SendEmailsTo from './sendEmailsTo';
import Language from './Language';
import { useNavigate } from 'react-router-dom';
import { MEDIA_URL, getSounds } from '../api';
const Sidebar = ({additionalClasses, setTitleDetails, controls, setControls, sLeadCapture, setSLeadCapture, titleDetails, imageFiles, showDiv, setImageFiles, onCreate, message, messageColor, privacy, setPrivacy, setButtons, buttons, loading, settings, setSettings, automation, setAutomation, analytics, setAnalytics}) => {
    const [dropdownStates, setDropdownStates] = useState({
      isTOpen: false,
      isBOpen: false,
      isCOpen: false,
      isPOpen: false,
      isLOpen: false,
      isCAOpen: false,
      isSOpen: false,
      isANOpen: false,
      isAUOpen: false,
    });
    const[bgchange, setBGChange] = useState(false)
    const[favchange, setFAVChange] = useState(false)
    const[logochange, setLOGOChange] = useState(false)
    const[sendEmailsTo, setSendEmailsTo] = useState(false)
    const[customPrivacyPolicy, setCustomPrivacyPolicy] = useState(false)
    const[allowedEmails, setAllowedEmails] = useState(false)
    const[language, setLanguage] = useState(false)
    const[cbgchange, setCBGChange] = useState(false)

    const toggleBGChange = () =>{
      setBGChange(!bgchange)
    }
    const toggleFAVChange = () =>{
      setFAVChange(!favchange)
    }
    const toggleLOGOChange = () =>{
      setLOGOChange(!logochange)
    }
    const toggleSendEmailsTo = () =>{
      setSendEmailsTo(!sendEmailsTo)
    }
    const toggleCustomPrivacyPolicy = () =>{
      setCustomPrivacyPolicy(!customPrivacyPolicy)
    }
    const toggleAllowedEmails = () =>{
      setAllowedEmails(!allowedEmails)
    }
    const toggleLanguage = () =>{
      setLanguage(!language)
    }
    const toggleCBGChange = () =>{
      setCBGChange(!cbgchange)
    }

    const toggleDropdown = (dropdownName) => {
      setDropdownStates((prevState) => {
        const newState = {};
        for (const key in prevState) {
          if (key === dropdownName) {
            newState[key] = !prevState[key];
          } else {
            newState[key] = false;
          }
        }
        return newState;
      });
    };
    const [passwordType, setPasswordType] = useState('public');
    const [password, setPassword] = useState(privacy?.password);
    const [showPassword, setShowPassword] = useState(false);
    const [noIndex, setNoIndex] = useState(false);

    const [download, setDownload] = useState(false);
    const [all, setAll] = useState(false);
    const [print, setPrint] = useState(false)
    
    const [flipSound, setFlipSound] = useState(false)
    const [fullScreen, setFullScreen] = useState(false);
    const [zoom, setZoom] = useState(false)

    const [toc, setToc] = useState(false);
    const toggleToc = () => {
      const newTocState = !toc;
      setToc(newTocState);
      setControls({
        ...controls,
        toc: newTocState,
      });
      if (!newTocState) {
        setAll(false);
      }
    };
    const [bookMark, setBookMark] = useState(false);
    const toggleBookMark = () => {
      const newBookMarkState = !bookMark;
      setBookMark(newBookMarkState);
      setControls({
        ...controls,
        bookMark: newBookMarkState,
      });
      if (!newBookMarkState) {
        setAll(false);
      }
    };
    const [selectTool, setSelectTool] = useState(false);
    const toggleSelectTool = () => {
      const newSelectToolState = !selectTool;
      setSelectTool(newSelectToolState);
      setControls({
        ...controls,
        selectTool: newSelectToolState,
      });
      if (!newSelectToolState) {
        setAll(false);
      }
    };
    const [pages, setPages] = useState(false);
    const togglePages = () => {
      const newPagesState = !pages;
      setPages(newPagesState);
      setControls({
        ...controls,
        pages: newPagesState,
      });
      if (!newPagesState) {
        setAll(false);
      }
    };

    const [bt1, setBt1] = useState(false)
    const [bt2, setBt2] = useState(false)
    const [btn1Data, setBt1Data] = useState()
    const [btn2Data, setBt2Data] = useState()
    const [enable, setEnable] = useState()
    const [leadCapture, setLeadCapture] = useState({
      leadTitle: "Enter your details to access flipbook",
      enabled: enable,
      name: {
        status: false,
        required: false,
        value: 'Name'
      },
      email:{
        status: false,
        required: false,
        value: 'Email'
      },
      phone:{
        status: false,
        required: false,
        value: 'Phone'
      },
      company:{
        status: false,
        required: false,
        value: 'Company'
      },
      customField1:{
        status: false,
        required: false,
        value: ''
      },
      customField2:{
        status: false,
        required: false,
        value: ''
      },
      allowSkip:{
        status: false,
        required: false,
      },
      verifyEmail:{
        status: false,
        required: false,
      },
      passwordTitle: "This is a private interactive book. To view it, please enter the password.",
      passwordText: "Password",
      passwordSubmit: "Submit", 
      submit:{
        value: "Submit",
      },
      skip:{
        value: "Skip",
      },
      emailVerficationTitle:"Enter 4 digit OTP sent to you",
      verifyEmailButton: "Verify",
 
    })
    const [published, setPublished] = useState(false)
    const togglePublished = () =>{
      setPublished(!published)
      setSettings({
        ...settings,
        published: !published
      })
    }
    const [rightToLeft, setRightToLeft] = useState(false)
    const toggleRightToLeft = () =>{
      setRightToLeft(!rightToLeft)
      setSettings({
        ...settings,
        rightToLeft: !rightToLeft
      })
    }
    const [showShareButtons, setShowShareButtons] = useState(false)
    const toggleShowShareButtons = () =>{
      setShowShareButtons(!showShareButtons)
      setSettings({
        ...settings,
        showShareButtons: !showShareButtons
      })
    }
    const [mailOnView, setMailOnView] = useState(false)
    const toggleMailOnView = () =>{
      setMailOnView(!mailOnView)
      setSettings({
        ...settings,
        mailOnView: !mailOnView
      })
    }
    const onToggleStatus = (fieldName) => {
      setLeadCapture(prevState => ({
        ...prevState,
        [fieldName]: {
          ...prevState[fieldName],
          status: !prevState[fieldName].status,
        }
      }));
    };
    const onToggleRequired = (fieldName) => {
      setLeadCapture(prevState => ({
        ...prevState,
        [fieldName]: {
          ...prevState[fieldName],
          required: !prevState[fieldName].required,
        }
      }));
    };
    

    const toggleEnable = () => {
      setEnable(!enable)
      setLeadCapture({
        ...leadCapture,
        enabled: !enable
      })
    }

    const toggleNoIndex = () => {
      setNoIndex(!noIndex);
      setPrivacy({
        ...privacy,
        index: !noIndex
      })
    };
    const toggleAll = () => {
      const toggles = {
        download: setDownload,
        print: setPrint,
        toc: setToc,
        flipSound: setFlipSound,
        fullScreen: setFullScreen,
        zoom: setZoom, 
        pages: setPages,
        bookMark: setBookMark,
        selectTool: setSelectTool,


      };
    
      Object.keys(toggles).forEach(key => toggles[key](!all));
      setAll(prevAll => !prevAll);
      setControls({
        ...controls,
        download: !all,
        print: !all,
        toc: !all,
        flipSound: !all,
        fullScreen: !all,
        zoom: !all,
        selectTool: !all,
        pages: !all,
        bookMark: !all,

      });
    };
    
    const toggleDownload = () => {
      const newDownloadState = !download;
      setDownload(newDownloadState);
      setControls({
        ...controls,
        download: newDownloadState,
      });
      if (!newDownloadState) {
        setAll(false);
      }
    };
    
    const togglePrint = () => {
      const newPrintState = !print;
      setPrint(newPrintState);
      setControls({
        ...controls,
        print: newPrintState,
      });
      if (!newPrintState) {
        setAll(false);
      }
    };
    
   
    
    const toggleFlipSound = () => {
      const newFlipSoundState = !flipSound;
      setFlipSound(newFlipSoundState);
      setControls({
        ...controls,
        flipSound: newFlipSoundState,
      });
      if (!newFlipSoundState) {
        setAll(false);
      }
    };
    
    const toggleFullScreen = () => {
      const newFullScreenState = !fullScreen;
      setFullScreen(newFullScreenState);
      setControls({
        ...controls,
        fullScreen: newFullScreenState,
      });
      if (!newFullScreenState) {
        setAll(false);
      }
    };
    
    const toggleZoom = () => {
      const newZoomState = !zoom;
      setZoom(newZoomState);
      setControls({
        ...controls,
        zoom: newZoomState,
      });
      if (!newZoomState) {
        setAll(false);
      }
    };
    
    const toggleBt1 = () => {
      setBt1(!bt1);
      if(bt1===true){
        setButtons({...buttons, button1: {...buttons?.button1, active: false}})
      }else{
        setButtons({...buttons, button1: {...buttons?.button1, active: true}})
      }
    };
    const toggleBt2 = () => {
      setBt2(!bt2);
      if(bt2===true){
        setButtons({...buttons, button2: {...buttons?.button2, active: false}})
      }else{
        setButtons({...buttons, button2: {...buttons?.button2, active: true}})
      }
    };

    const handlePasswordTypeChange = (event) => {
      setPasswordType(event.target.value);
      setPassword('');
    };

    const handlePasswordChange = (event) => {
      setPassword(event.target.value);
    };

    const toggleShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const [status, setStatus] = useState()

    useEffect(()=>{

      const getSoundFiles = async() =>{
        try{
          const response = await getSounds();
          console.log("SOUNDS", response)
        }catch(error){
          console.error(error)
        }
      }
      getSoundFiles();

    },[])

    useEffect(()=>{
      if(password){
        setPrivacy({
          privacyType: 'private',
          password: password
        })
        setStatus("Private Set!")
      }else{
        setPrivacy({
          privacyType: 'public',
          
        })
        setStatus("Public Set!")
      }
    }, [password])
    useEffect(()=>{
      if(privacy?.password){
        setPassword(privacy?.password)
        setPasswordType('password')
      }
      if(buttons){
        if(buttons?.button1?.active){
          setBt1Data(buttons?.button1)
          setBt1(true)
        }
        if(buttons?.button2?.active){
          setBt2Data(buttons?.button2)
          setBt2(true)
        }
        
      }
    }, [privacy])
    useEffect(()=>{
     
      if(settings?.published){
        setPublished(settings?.published)
      }
      if(settings?.rightToLeft){
        setRightToLeft(settings?.rightToLeft)
      }
      if(settings?.showShareButtons){
        setShowShareButtons(settings?.showShareButtons)
      }
      if(settings?.mailOnView){
        setMailOnView(settings?.mailOnView)
      }
     
    }, [settings])

    useEffect(()=>{
     
      if(controls?.download){
        setDownload(controls?.download)
      }
      if(controls?.print){
        setPrint(controls?.print)
      }
      if(controls?.toc){
        setToc(controls?.toc)
      }
      if(controls?.fullScreen){
        setFullScreen(controls?.fullScreen)
      }
      if(controls?.flipSound){
        setFlipSound(controls?.flipSound)
      }
      if(controls?.zoom){
        setZoom(controls?.zoom)
      }
      if(controls?.pages){
        setPages(controls?.pages)
      }
      if(controls?.selectTool){
        setSelectTool(controls?.selectTool)
      }
      if(controls?.bookMark){
        setBookMark(controls?.bookMark)
      }
     
    }, [controls])
    useEffect(()=>{
      if(sLeadCapture?.enabled){
        setEnable(sLeadCapture?.enabled)
        setLeadCapture(sLeadCapture)
      }
    },[sLeadCapture])
    useEffect(()=>{
      if(leadCapture){
        setSLeadCapture(leadCapture)
      }
    },[leadCapture])
    useEffect(()=>{
      
      if (!enable) {
        // Set all required and status fields to false
        setLeadCapture(prevState => {
          const newState = { ...prevState };
          Object.keys(newState).forEach(fieldName => {
            if (typeof newState[fieldName] === 'object') {
              newState[fieldName] = {
                ...newState[fieldName],
                required: false,
                status: false
              };
            }
          });
          return newState;
        });
      }
    }, [enable])
   useEffect(()=>{
      if(btn1Data){
        setButtons({
          ...buttons,
          button1: btn1Data
        })
      }
      if(btn2Data){
        setButtons({
          ...buttons,
          button2: btn2Data
        })
      }
      
   }, [btn1Data, btn2Data])

   const navigate = useNavigate()
    return (
            <div
            className={`flex flex-col shadow-full w-[16%] h-[95%] items-center rounded-[1.15rem] bg-white my-4 mx-6 ${!showDiv ? 'hidden' : "absolute w-[300px] right-0 z-50"} lg:flex ${additionalClasses}`}
          >
            <p className='font-bold my-4'>Customize Interactive Book</p>
            <div className='flex w-[80%] flex-col mx-2 items-center'>
              <div className='w-[80%] flex text-sm'>
                  <p className='font-bold p-2 w-[50%] mx-1 rounded-[8px] bg-[#8392AB] text-white cursor-pointer  ease-in-out duration-300' onClick={(e)=> navigate('/dashboard')}>CANCEL</p>
                  <p className='font-bold p-2 bg-[#1E78C8] w-[50%] mx-1 text-white rounded-[8px] cursor-pointer  ease-in-out duration-300' onClick={onCreate}>{!loading? 'SAVE': <Loader/>}</p>
              </div>
              <p className={`mt-4 -mb-3 font-bold ${messageColor} text-[15px]`}>{message}</p>
              
            </div>
           
          <div className='overflow-y-auto relative my-2 max-h-[90%] w-full text-[14px] '>
              
            
              <div className='flex justify-between mx-6 mt-6 font-[16px]'>
                  <p className={`cursor-pointer ${dropdownStates.isTOpen&& 'font-bold'}`}>Title , Description, URL</p>
                  <p onClick={() => toggleDropdown('isTOpen')} className={`cursor-pointer rounded-full ${dropdownStates.isTOpen? "bg-[#EBECF0]":""} duration-300 h-[30px] p-1`}>{!dropdownStates.isTOpen ? (<span className="material-symbols-outlined transition-all duration-300">expand_more</span>): (<span className="material-symbols-outlined transition-all duration-300">expand_less</span>)}</p>
              </div>
           
            {dropdownStates.isTOpen &&(
                <div className='flex flex-col relative text-left mx-6'>
                    <div className='my-3'>
                      <div className='flex justify-between '>
                        <label htmlFor="title">Title <span className='text-[#8392AB]'>(max 200 characters)</span></label>
                        <input type="color" name="title-color" id="" className='bg-transparent rounded-md w-6 h-[35px] w-[30px]' 
                        
                        value={titleDetails?.color} onChange={(e)=>setTitleDetails({...titleDetails, color: e.target.value})}/>
                      </div>
                      <input className="border border-[#EBECF0] w-full px-2 py-3 rounded-[0.5rem] mt-2" value={titleDetails?.title} name="title" onChange={(e)=>setTitleDetails({...titleDetails, title: e.target.value})}/>
                    </div>
                    <div className='my-3'>
                      <label htmlFor="description">Description <span className='text-[#8392AB]'>(max 500 characters)</span></label>
                      <textarea className="border border-[#EBECF0] w-full px-2 py-3 rounded-[0.5rem] mt-2" name="description" rows={5} value={titleDetails?.description} onChange={(e)=>setTitleDetails({...titleDetails, description: e.target.value})}/>
                    </div>
                    <div className='my-3'>
                      <label htmlFor="title">Custom URL <span className='text-[#8392AB]'>(max 200 characters)</span></label>
                      <input 
                          className="border mt-2 border-[#EBECF0] w-full px-2 py-3 rounded-[0.5rem] " name="title" value={titleDetails?.url} 
                          onChange={(e)=>{
                            setTitleDetails({...titleDetails, url: e.target.value})
                          }}
                      />
                    </div>
                </div>
            )}
            <div className='flex justify-between mx-6 my-6'>
                <p className={`cursor-pointer ${dropdownStates.isBOpen&& 'font-bold'}`}>Branding</p>
                <p onClick={() => toggleDropdown('isBOpen')} className={`cursor-pointer rounded-full ${dropdownStates.isBOpen? "bg-[#EBECF0]":""} duration-300 h-[30px] p-1`}>{!dropdownStates.isBOpen ? (<span className="material-symbols-outlined transition-all duration-300">expand_more</span>): (<span className="material-symbols-outlined transition-all duration-300">expand_less</span>)}</p>
            </div>
            {dropdownStates.isBOpen &&(
              <div className='text-left mx-6'>
                    <div className='flex justify-between my-3'>
                      <p>Logo</p>
                      <button onClick={toggleLOGOChange} className='bg-[#EBECF0] px-3 py-1 rounded-[0.25rem] hover:bg-[#1E78C8] hover:text-white ease-in-out duration-300'>Change</button>
                      
                      {logochange&&
                        <>
                          
                          <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                            <ChangeLogo onClose={toggleLOGOChange} imageFiles={imageFiles} setImageFiles={setImageFiles} />
                          </div>
                        </>
                      }
                    </div>
                    <div className='flex justify-between my-3'>
                      <p>Background Image</p>
                      <button onClick={toggleBGChange} className='bg-[#EBECF0] px-3 py-1 rounded-[0.25rem] hover:bg-[#1E78C8] hover:text-white ease-in-out duration-300'>Change</button>
                      
                       {bgchange&&
                        <>
                          
                          <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                            <ChangeBackground onClose={toggleBGChange} imageFiles={imageFiles} setImageFiles={setImageFiles} />
                          </div>
                        </>
                      }
                    </div>
                    <div className='flex justify-between my-3'>
                      <p>Custom Background</p>
                      <button onClick={toggleCBGChange} className='bg-[#EBECF0] px-3 py-1 rounded-[0.25rem] hover:bg-[#1E78C8] hover:text-white ease-in-out duration-300'>Change</button>
                    
                      {cbgchange&&
                      <>
                          
                          <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                          <CustomBackground onClose={toggleCBGChange} imageFiles={imageFiles} setImageFiles={setImageFiles} />
                          </div>
                        </>
                      }
                    </div>
                    <div className='flex justify-between my-3'>
                      <p>Favicon</p>
                      <button onClick={toggleFAVChange} className='bg-[#EBECF0] px-3 py-1 rounded-[0.25rem] hover:bg-[#1E78C8] hover:text-white ease-in-out duration-300'>Change</button>
                     
                      {favchange&&
                      <>
                          
                          <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                            <ChangeFavicon onClose={toggleFAVChange} imageFiles={imageFiles} setImageFiles={setImageFiles} />
                          </div>
                        </>
                      }
                    </div>

              </div>
            )}
            <div className='flex justify-between mx-6 mt-4'>
                <p className={`cursor-pointer ${dropdownStates.isCOpen&& 'font-bold'}`}>Controls</p>
                <p onClick={() => toggleDropdown('isCOpen')} className={`cursor-pointer rounded-full ${dropdownStates.isCOpen? "bg-[#EBECF0]":""} duration-300 h-[30px] p-1`}>{!dropdownStates.isCOpen ? (<span className="material-symbols-outlined transition-all duration-300">expand_more</span>): (<span className="material-symbols-outlined transition-all duration-300">expand_less</span>)}</p>
            </div>
            {dropdownStates.isCOpen && (
              <div className="mx-6 bg-white rounded-lg py-2 transition-all duration-300">
                <div className='flex items-center mt-4 justify-between '>
                   <span className="ml-2">All</span>
                       <input
                         type="checkbox"
                         id="all"
                         checked={all}
                         onChange={toggleAll}
                         className="sr-only"
                       />

                       <label htmlFor="all" className="cursor-pointer -mr-2">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${all ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${all ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${all ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                </div>
                <div className='flex items-center mt-4 justify-between '>
                    <div className='flex'>
                      <img src="/icons/controls/print.svg" alt="print" />
                      <span className="ml-2">Print</span>
                    </div>
                       <input
                         type="checkbox"
                         id="print"
                         checked={print}
                         onChange={togglePrint}
                         className="sr-only"
                       />

                       <label htmlFor="print" className="cursor-pointer -mr-2">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${print ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${print ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${print ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                </div>
                <div className='flex items-center mt-4 justify-between '>
                <div className='flex'>
                      <img src="/icons/controls/download.svg" alt="print" />
                      <span className="ml-2">Download</span>
                    </div>
                       <input
                         type="checkbox"
                         id="download"
                         checked={download}
                         onChange={toggleDownload}
                         className="sr-only"
                       />

                       <label htmlFor="download" className="cursor-pointer -mr-2">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${download ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${download ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${download ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                </div>
                <div className='flex items-center mt-4 justify-between '>
                  <div className='flex'>
                      <img src="/icons/controls/toa.svg" alt="print" />
                      <span className="ml-2">Table of Contents</span>
                    </div>  
                       <input
                         type="checkbox"
                         id="tableOfContents"
                         checked={toc}
                         onChange={toggleToc}
                         className="sr-only"
                       />

                       <label htmlFor="tableOfContents" className="cursor-pointer -mr-2">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${toc ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${toc ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${toc ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                </div>
                <div className='flex items-center mt-4 justify-between '>
                  <div className='flex'>
                      <span className="material-symbols-outlined"> auto_stories </span>
                      <span className="ml-2">Pages</span>
                    </div>  
                       <input
                         type="checkbox"
                         id="pages"
                         checked={pages}
                         onChange={togglePages}
                         className="sr-only"
                       />

                       <label htmlFor="pages" className="cursor-pointer -mr-2">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${pages ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${pages ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${pages ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                </div>
                <div className='flex items-center mt-4 justify-between '>
                  <div className='flex'>
                      <span className="material-symbols-outlined">highlight_text_cursor</span>
                      <span className="ml-2">Select Tool</span>
                    </div>  
                       <input
                         type="checkbox"
                         id="selectTool"
                         checked={selectTool}
                         onChange={toggleSelectTool}
                         className="sr-only"
                       />

                       <label htmlFor="selectTool" className="cursor-pointer -mr-2">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${selectTool ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${selectTool ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${selectTool ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                </div>
                <div className='flex items-center mt-4 justify-between '>
                  <div className='flex'>
                      <span className="material-symbols-outlined">bookmark</span>
                      <span className="ml-2">Bookmark</span>
                    </div>  
                       <input
                         type="checkbox"
                         id="bookMark"
                         checked={bookMark}
                         onChange={toggleBookMark}
                         className="sr-only"
                       />

                       <label htmlFor="bookMark" className="cursor-pointer -mr-2">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${bookMark ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${bookMark ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${bookMark ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                </div>
                <div className='flex items-center mt-4 justify-between '>
                  <div className='flex'>
                      <img src="/icons/controls/flipSound.svg" alt="print" />
                      <span className="ml-2">Flip Sound</span>
                    </div>
                       <input
                         type="checkbox"
                         id="flipSound"
                         checked={flipSound}
                         onChange={toggleFlipSound}
                         className="sr-only"
                       />

                       <label htmlFor="flipSound" className="cursor-pointer -mr-2">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${flipSound ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${flipSound ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${flipSound ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                </div>
                <div className='flex items-center mt-4 justify-between '>
                  <div className='flex'>
                      <img src="/icons/controls/fullScreen.svg" alt="print" />
                      <span className="ml-2">Full Screen</span>
                    </div>
                       <input
                         type="checkbox"
                         id="fullScreen"
                         checked={fullScreen}
                         onChange={toggleFullScreen}
                         className="sr-only"
                       />

                       <label htmlFor="fullScreen" className="cursor-pointer -mr-2">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${fullScreen ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${fullScreen ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${fullScreen ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                </div>
                <div className='flex items-center mt-4 justify-between '>
                  <div className='flex'>
                      <img src="/icons/controls/zoom.svg" alt="print" />
                      <span className="ml-2">Zoom</span>
                    </div>
                       <input
                         type="checkbox"
                         id="zoom"
                         checked={zoom}
                         onChange={toggleZoom}
                         className="sr-only"
                       />

                       <label htmlFor="zoom" className="cursor-pointer -mr-2">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${zoom ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${zoom ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${zoom ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                </div>
                
                
               
                <div className='flex  justify-between my-3'>
                    <p className="flex">
                     <img src="/icons/controls/music.svg" alt="print" />
                     <span className="ml-2">Music</span>
                    </p>
                    <select className='bg-[#EBECF0] text-[#334564DB] py-1.5 rounded-[0.25rem] px-2 text-gray-800' defaultValue={'None'}>
                      <option value={'None'}>None</option>
                    </select>
                    <audio controls className='hidden' autoPlay>
                        <source src={`${MEDIA_URL}/media/sounds/sound1.mp3`} />
                        
                    </audio>
                </div>
                <div className='flex justify-between my-3'>
                    <p className="flex w-[60%]">
                        <img src="/icons/controls/play.svg" alt="print" />
                           <span className="ml-2 whitespace-nowrap">Auto-flip on Play</span>
                        </p>
                      <select className='bg-[#EBECF0]  text-[#334564DB] w-[70px] px-2 py-1.5 rounded-[0.25rem] text-gray-800' value={controls?.autoplay} onChange={(e)=> setControls({...controls, autoplay: e.target.value})}>
                        <option value="0">No</option>
                        <option value="3000">3s</option>
                        <option value="4000">4s</option>
                        <option value="5000">5s</option>
                        <option value="7000">7s</option>
                        <option value="10000">10s</option>
                        <option value="15000">15s</option>
                        <option value="20000">20s</option>
                      </select>
                </div>
                
                
               
              </div>
            )}
            <div className='flex justify-between mx-6 my-6'>
                <p className={`cursor-pointer ${dropdownStates.isPOpen&& 'font-bold'}`}>Privacy</p>
                <p onClick={() => toggleDropdown('isPOpen')} className={`cursor-pointer rounded-full ${dropdownStates.isPOpen? "bg-[#EBECF0]":""} duration-300 h-[30px] p-1`}>{!dropdownStates.isPOpen ? (<span className="material-symbols-outlined transition-all duration-300">expand_more</span>): (<span className="material-symbols-outlined transition-all duration-300">expand_less</span>)}</p>
            </div>
            {dropdownStates.isPOpen&&(
               <div className='text-left mx-6'>
               <div className='flex my-2'>
                 <input
                   type="radio"
                   id="public"
                   name="passwordType"
                   value="public"
                   checked={passwordType === 'public'}
                   onChange={handlePasswordTypeChange}
                 />
                 <div className='flex ml-2'><img src="/icons/privacy/public.svg" alt="print" /><label htmlFor="public" className='ml-2'>Public</label></div>
               </div>
               <div className='flex my-2'>
                 <input
                   type="radio"
                   id="password"
                   name="passwordType"
                   value="password"
                   checked={passwordType === 'password'}
                   onChange={handlePasswordTypeChange}
                 />
                 <div className='flex ml-2 '><img src="/icons/privacy/private.svg" alt="print" /><label htmlFor="password" className='ml-2 whitespace-nowrap'>Private <span className='text-[12px] text-[#334564BF]'>(Password Needed)</span></label></div>
               </div>
               {passwordType === 'password' && (
                 <div className='my-1'>
                  <label htmlFor="password">Password</label>
                  <div className="relative w-full">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="border border-[#EBECF0] w-full pr-10 px-2 py-3 rounded-[0.5rem]" // Add paddingRight to make space for the button
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <button
                      onClick={toggleShowPassword}
                      className="absolute inset-y-0 right-0 px-3"
                    >
                      {showPassword ? <span className="material-symbols-outlined"> visibility_off </span> : <span className="material-symbols-outlined"> visibility </span>}
                    </button>
                  </div>
                </div>
               )}
               <div className="flex items-center mt-4 justify-between ">
                    <p className="flex">
                      <span className="material-symbols-outlined"> public </span>
                      <span className='ml-2'>No Index</span>
                    </p>
                    <input
                      type="checkbox"
                      id="noIndex"
                      checked={noIndex}
                      onChange={toggleNoIndex}
                      className="sr-only"
                    />
                  
                    <label htmlFor="noIndex" className="cursor-pointer  -mr-2">
                      <div className="relative w-12 h-6">
                        <div className={`w-10 h-5 rounded-full shadow-inner ${noIndex ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                        <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${noIndex ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${noIndex ? 'translate-x-full' : 'translate-x-0'}`}></div>
                      </div>
                    </label>

                </div>
                {/* <p className='font-bold text-yellow-400 mt-3 text-center'>{status}</p> */}
             </div>
            )}
          
            <div className='flex justify-between mx-6 my-6'>
                <p className={`cursor-pointer ${dropdownStates.isLOpen&& 'font-bold'}`}>Lead Capture Form</p>
                <p onClick={() => toggleDropdown('isLOpen')} className={`cursor-pointer rounded-full ${dropdownStates.isLOpen? "bg-[#EBECF0]":""} duration-300 h-[30px] p-1`}>{!dropdownStates.isLOpen ? (<span className="material-symbols-outlined transition-all duration-300">expand_more</span>): (<span className="material-symbols-outlined transition-all duration-300">expand_less</span>)}</p>
            </div>
            {dropdownStates.isLOpen&&(
              <div className='text-left mx-6'>
                <div className='flex items-center mt-4 justify-between'>
                   <span className="">Enable</span>
                       <input
                         type="checkbox"
                         id="enable"
                         checked={enable}
                         onChange={toggleEnable}
                         className="sr-only"
                       />

                       <label htmlFor="enable" className="cursor-pointer  -mr-2">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${enable ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${enable ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${enable ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                </div>
                <p className="text-[14px] text-[#EBECF0] border-b border-[#EBECF0] w-full my-2"></p>
                {enable?(
                  <>
                   <div className='flex items-center mt-0 justify-between my-2'>
                   <span className="">Name</span>
                   <div className="flex items-center">
                      <button
                        className="focus:outline-none"
                        onClick={(e)=>onToggleRequired("name")}
                      >
                        <span className={`material-symbols-outlined mt-2 mr-3 text-[18px] ${leadCapture?.name?.required ? 'text-blue-500' : 'text-[#EBECF0]'}`}> stars </span>
                        
                      </button>
                      <input
                         type="checkbox"
                         id="name"
                         checked={leadCapture?.name?.status}
                         onChange={(e)=> onToggleStatus("name")}
                         className="sr-only"
                       />

                       <label htmlFor="name" className="cursor-pointer  -mr-2">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${leadCapture?.name?.status ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${leadCapture?.name?.status ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${leadCapture?.name?.status ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                      </label>
                    </div>



                       
                </div>
                <div className='flex items-center mt-0 justify-between my-2'>
                   <span className="">Email</span>
                   <div className="flex items-center">
                      <button
                        className="focus:outline-none"
                        onClick={(e)=>onToggleRequired("email")}
                      >
                        <span className={`material-symbols-outlined mt-2 mr-3 text-[18px] ${leadCapture?.email?.required ? 'text-blue-500' : 'text-[#EBECF0]'}`}> stars </span>
                        
                      </button>
                       <input
                         type="checkbox"
                         id="email"
                         checked={leadCapture?.email?.status}
                         onChange={(e)=> onToggleStatus("email")}
                         className="sr-only"
                       />

                       <label htmlFor="email" className="cursor-pointer  -mr-2">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${leadCapture?.email?.status ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${leadCapture?.email?.status ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${leadCapture?.email?.status ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                  </div>
                </div>
                <div className='flex items-center mt-0 justify-between my-2'>
                   <span className="">Phone</span>
                       <div className="flex items-center">
                      <button
                        className="focus:outline-none"
                        onClick={(e)=>onToggleRequired("phone")}
                      >
                        <span className={`material-symbols-outlined mt-2 mr-3 text-[18px] ${leadCapture?.phone?.required ? 'text-blue-500' : 'text-[#EBECF0]'}`}> stars </span>
                        
                      </button>
                       <input
                         type="checkbox"
                         id="phone"
                         checked={leadCapture?.phone?.status}
                         onChange={(e)=> onToggleStatus("phone")}
                         className="sr-only"
                       />

                       <label htmlFor="phone" className="cursor-pointer  -mr-2">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${leadCapture?.phone?.status ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${leadCapture?.phone?.status ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${leadCapture?.phone?.status ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                   </div>
                </div>
                <div className='flex items-center mt-0 justify-between my-2'>
                   <span className="">Company</span>
                       <div className="flex items-center">
                      <button
                        className="focus:outline-none"
                        onClick={(e)=>onToggleRequired("company")}
                      >
                        <span className={`material-symbols-outlined mt-2 mr-3 text-[18px] ${leadCapture?.company?.required ? 'text-blue-500' : 'text-[#EBECF0]'}`}> stars </span>
                        
                      </button>
                       <input
                         type="checkbox"
                         id="company"
                         checked={leadCapture?.company?.status}
                         onChange={(e)=> onToggleStatus("company")}
                         className="sr-only"
                       />

                       <label htmlFor="company" className="cursor-pointer  -mr-2">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${leadCapture?.company?.status ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${leadCapture?.company?.status ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${leadCapture?.company?.status ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                   </div>
                </div>
                <div className='flex items-center mt-0 justify-between my-2'>
                   <span className="" contentEditable={true}  onInput={(e) =>
                        setLeadCapture({
                          ...leadCapture,
                          customField1: {
                            ...leadCapture?.customField1,
                            value: e.target.textContent
                          }
                        })
                      }>Custom Field 1</span>
                       <div className="flex items-center">
                      <button
                        className="focus:outline-none"
                        onClick={(e)=>onToggleRequired("customField1")}
                      >
                        <span className={`material-symbols-outlined mt-2 mr-3 text-[18px] ${leadCapture?.customField1?.required ? 'text-blue-500' : 'text-[#EBECF0]'}`}> stars </span>
                        
                      </button>
                       <input
                         type="checkbox"
                         id="customField1"
                         checked={leadCapture?.customField1?.status}
                         onChange={(e)=> onToggleStatus("customField1")}
                         className="sr-only"
                       />

                       <label htmlFor="customField1" className="cursor-pointer  -mr-2">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${leadCapture?.customField1?.status ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${leadCapture?.customField1?.status ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${leadCapture?.customField1?.status ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                   </div>
                </div>
                <div className='flex items-center mt-0 justify-between my-2'>
                   <span className="" contentEditable={true} 
                    onInput={(e) =>
                      setLeadCapture({
                        ...leadCapture,
                        customField2: {
                          ...leadCapture?.customField2,
                          value: e.target.textContent 
                        }
                      })
                    }
                      >Custom Field 2</span>
                       <div className="flex items-center">
                      <button
                        className="focus:outline-none"
                        onClick={(e)=>onToggleRequired("customField2")}
                      >
                        <span className={`material-symbols-outlined mt-2 mr-3 text-[18px] ${leadCapture?.customField2?.required ? 'text-blue-500' : 'text-[#EBECF0]'}`}> stars </span>
                        
                      </button>
                       <input
                         type="checkbox"
                         id="customField2"
                         checked={leadCapture?.customField2?.status}
                         onChange={(e)=> onToggleStatus("customField2")}
                         className="sr-only"
                       />

                       <label htmlFor="customField2" className="cursor-pointer  -mr-2">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${leadCapture?.customField2?.status ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${leadCapture?.customField2?.status ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${leadCapture?.customField2?.status ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                   </div>
                </div>
                <div className='flex flex-col my-4'>
                      <label htmlFor="title" className="">Lead title</label>
                      <input className="border border-[#EBECF0] w-[100%] px-2 py-3 mt-1 rounded-[0.5rem] p-2" name="title" value={leadCapture?.leadTitle} onChange={(e)=>setLeadCapture({...leadCapture, leadTitle: e.target.value})}/>
                    </div>
                <div className='flex justify-between my-4'>
                      <p className="" >Custom Privacy Policy</p>
                      <button onClick={toggleCustomPrivacyPolicy}  className='bg-[#EBECF0] px-3 py-1 rounded-[0.25rem] hover:bg-[#1E78C8] hover:text-white ease-in-out duration-300'>Set</button>
                      {customPrivacyPolicy&&
                        <>
                          <AddPrivacyPolicy leadCapture={leadCapture} setLeadCapture={setLeadCapture} onClose={toggleCustomPrivacyPolicy} />
                          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
                        </>
                      }
                </div>
                <div className='flex justify-between my-4'>
                      <p className="" >Email Allowed</p>
                      <button onClick={toggleAllowedEmails}  className='bg-[#EBECF0] px-3 py-1 rounded-[0.25rem] hover:bg-[#1E78C8] hover:text-white ease-in-out duration-300'>Set</button>
                      {allowedEmails&&
                        <>
                          <AllowedEmails leadCapture={leadCapture} setLeadCapture={setLeadCapture} onClose={toggleAllowedEmails} />
                          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
                        </>
                      }
                </div>
                <div className='flex items-center mt-0 justify-between my-4'>
                   <span className="">Allow Skip</span>
                       
                       <input
                         type="checkbox"
                         id="allowSkip"
                         checked={leadCapture?.allowSkip?.status}
                         onChange={(e)=> onToggleStatus("allowSkip")}
                         className="sr-only"
                       />

                       <label htmlFor="allowSkip" className="cursor-pointer  -mr-2">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${leadCapture?.allowSkip?.status ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${leadCapture?.allowSkip?.status ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${leadCapture?.allowSkip?.status ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                   
                </div>
                <div className='flex items-center mt-0 justify-between my-3'>
                   <span className="">Verify Email</span>
                      
                       <input
                         type="checkbox"
                         id="leadCapture?.verifyEmail?.status"
                         checked={leadCapture?.verifyEmail?.status}
                         onChange={(e)=> onToggleStatus("verifyEmail")}
                         className="sr-only"
                       />

                       <label htmlFor="leadCapture?.verifyEmail?.status" className="cursor-pointer  -mr-2">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${leadCapture?.verifyEmail?.status ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${leadCapture?.verifyEmail?.status ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${leadCapture?.verifyEmail?.status ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                   
                </div>
                  </>
                ):(
                  <></>
                )}
              </div>
            )}
            <div className='flex justify-between mx-6 my-6'>
                <p className={`cursor-pointer ${dropdownStates.isCAOpen&& 'font-bold'}`}>Call To Action Button</p>
                <p onClick={() => toggleDropdown('isCAOpen')} className={`cursor-pointer rounded-full ${dropdownStates.isCAOpen? "bg-[#EBECF0]":""} duration-300 h-[30px] p-1`}>{!dropdownStates.isCAOpen ? (<span className="material-symbols-outlined transition-all duration-300">expand_more</span>): (<span className="material-symbols-outlined transition-all duration-300">expand_less</span>)}</p>
            </div>
            {dropdownStates.isCAOpen&&(
              <div className='mx-6'>
               <div className='flex items-center mt-4 justify-between'>
                   <span className="">Enable Button 1</span>
                       <input
                         type="checkbox"
                         id="bt1"
                         checked={bt1}
                         onChange={toggleBt1}
                         className="sr-only"
                         
                       />

                       <label htmlFor="bt1" className="cursor-pointer">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${bt1 ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${bt1 ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${bt1 ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                </div>
                {bt1&&(
                  <div className='text-left flex-col '>
                    <div>
                      <label htmlFor="bt1text">Button 1 Text</label>
                      <input className="border border-[#EBECF0] w-full px-2 py-1 rounded-[0.5rem] " value={buttons?.button1?.bt1Text} name="bt1text" type='text' onChange={(e)=> setButtons({...buttons, button1:{...buttons?.button1, bt1Text: e.target.value}})}/>
                    </div>
                   
                    <div>
                      <label htmlFor="bt1tcolor">Button 1 Text Color</label>
                      <input className="w-full rounded-[0.5rem] p-0 bg-transparent h-[3rem]"
                        
                      
                      value={buttons?.button1?.bt1TextColor} name="bt1tcolor" type='color' onChange={(e)=> setButtons({...buttons, button1:{...buttons?.button1, bt1TextColor: e.target.value}})}/>
                    </div>
                    <div>
                      <label htmlFor="bt1color">Button 1 Color</label>
                      <input className="w-full rounded-[0.5rem] p-0 bg-transparent h-[3rem]" value={buttons?.button1?.bt1Color} name="bt1color" type='color' onChange={(e)=> setButtons({...buttons, button1:{...buttons?.button1, bt1Color: e.target.value}})}/>
                    </div>
                    <div>
                      <label htmlFor="bt1tlink">Web Address URL</label>
                      <input className="border border-[#EBECF0] w-full px-2 py-1 rounded-[0.5rem] " value={buttons?.button1?.bt1Url} name="bt1tlink" type='text' onChange={(e)=> setButtons({...buttons, button1:{...buttons?.button1, bt1Url: e.target.value}})}/>
                    </div>
                  </div>
                )}
               <div className='flex items-center mt-4 justify-between '>
                   <span className="">Enable Button 2</span>
                       <input
                         type="checkbox"
                         id="bt2"
                         checked={bt2}
                         onChange={toggleBt2}
                         className="sr-only"
                       />

                       <label htmlFor="bt2" className="cursor-pointer">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${bt2 ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${bt2 ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${bt2 ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                </div>
                {bt2&&(
                  <div className='text-left flex-col '>
                    <div>
                      <label htmlFor="bt2text">Button 2 Text</label>
                      <input className="border border-[#EBECF0] w-full px-2 py-1 rounded-[0.5rem] " value={buttons?.button2?.bt2Text} name="bt2text" type='text' onChange={(e)=> setButtons({...buttons, button2:{...buttons?.button2, bt2Text: e.target.value}})}/>
                    </div>
                    
                    <div>
                      <label htmlFor="bt2tcolor">Button 2 Text Color</label>
                      <input className="w-full rounded-[0.5rem] p-0 bg-transparent h-[3rem]"
                      style={{
                          WebkitAppearance: 'none',
                          MozAppearance: 'none',
                          appearance: 'none',
                          '::webkit-color-swatch': {
                              borderRadius: '15px',
                              border: 'none'
                          },
                          '::moz-color-swatch': {
                              borderRadius: '15px',
                              border: 'none'
                          }
                      }}
                      value={buttons?.button2?.bt2TextColor} name="bt2tcolor" type='color' onChange={(e)=> setButtons({...buttons, button2:{...buttons?.button2, bt2TextColor: e.target.value}})}/>
                    </div>
                    <div>
                      <label htmlFor="bt2color">Button 2 Color</label>
                      <input className="w-full rounded-[0.5rem] p-0 bg-transparent h-[3rem]"
                      style={{
                          WebkitAppearance: 'none',
                          MozAppearance: 'none',
                          appearance: 'none',
                          '::webkit-color-swatch': {
                              'border-radius': '50%',
                              border: 'none'
                          },
                          '::moz-color-swatch': {
                              'border-radius': '50%',
                              border: 'none'
                          }
                      }}
                      value={buttons?.button2?.bt2Color} name="bt2color" type='color' onChange={(e)=> setButtons({...buttons, button2:{...buttons?.button2, bt2Color: e.target.value}})}/>
                    </div>
                    <div>
                      <label htmlFor="bt2tlink">Web Address URL</label>
                      <input className="border border-[#EBECF0] w-full px-2 py-1 rounded-[0.5rem] " value={buttons?.button2?.bt2Url} name="bt2tlink" type='text' onChange={(e)=> setButtons({...buttons, button2:{...buttons?.button2, bt2Url: e.target.value}})}/>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className='flex justify-between mx-6 my-6'>
                <p className={`cursor-pointer ${dropdownStates.isSOpen&& 'font-bold'}`}>Settings</p>
                <p onClick={() => toggleDropdown('isSOpen')} className={`cursor-pointer rounded-full ${dropdownStates.isSOpen? "bg-[#EBECF0]":""} duration-300 h-[30px] p-1`}>{!dropdownStates.isSOpen ? (<span className="material-symbols-outlined transition-all duration-300">expand_more</span>): (<span className="material-symbols-outlined transition-all duration-300">expand_less</span>)}</p>
            </div>
            {dropdownStates.isSOpen&&(
              <div className=' mx-6 text-left '>
                <p className='text-[#334564BF]'>Settings will refect after you save the flipbook</p>
                <div className='flex justify-between my-3'>
                <p className="flex">Layout</p>
                    <select className='bg-[#EBECF0] py-1.5 text-[#334564DB] rounded-[0.25rem] px-2 text-gray-800 w-[6.5rem]' defaultValue={settings?.layout} onChange={(e)=>setSettings({...settings, 'layout': e.target.value})}>
                      <option value={1}>1 Page</option>
                      <option value={2}>2 Pages</option>
                    </select>
                </div>
                {/* <div className='flex justify-between my-3'>
                <p className="flex">Page Thickness</p>
                  <select className='bg-[#EBECF0] py-1.5 text-[#334564DB] rounded-[0.25rem] px-2 text-gray-800 w-[90px]' defaultValue={settings?.pageThickness} onChange={(e)=>setSettings({...settings, 'pageThickness': e.target.value})}>
                    <option value={'flat'}>Flat</option>
                    <option value={'thin'}>Thin</option>
                    <option value={'regular'}>Regular</option>
                    <option value={'thick'}>Thick</option>
                  </select>
                </div> <div className='flex justify-between my-3'>
                <p className="flex">Cover Type</p>
                  <select className='bg-[#EBECF0] py-1.5 text-[#334564DB] rounded-[0.25rem] px-2 text-gray-800 w-[130px]' defaultValue={settings?.coverType} onChange={(e)=>setSettings({...settings, 'coverType': e.target.value})}>
                    <option value={'paperback'}>Paperback</option>
                    <option value={'hardCover'}>Hard Cover</option>
                    </select>
                </div> */}
                <div className='flex items-center mt-4 justify-between'>
                   <span className="">Righ To Left</span>
                       <input
                         type="checkbox"
                         id="rightToLeft"
                         checked={rightToLeft}
                         onChange={toggleRightToLeft}
                         className="sr-only"
                       />

                       <label htmlFor="rightToLeft" className="cursor-pointer -mr-2">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${rightToLeft ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${rightToLeft ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${rightToLeft ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                </div>
                <div className='flex items-center mt-4 justify-between'>
                   <span className="">Send Email on View</span>
                       <input
                         type="checkbox"
                         id="mailOnView"
                         checked={mailOnView}
                         onChange={toggleMailOnView}
                         className="sr-only"
                       />

                       <label htmlFor="mailOnView" className="cursor-pointer -mr-2">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${mailOnView ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${mailOnView ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${mailOnView ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                </div>
                <div className='flex justify-between my-4'>
                      <p>Send Email To</p>
                      <button onClick={toggleSendEmailsTo}  className='bg-[#EBECF0] px-3 py-1 rounded-[0.25rem] hover:bg-[#1E78C8] hover:text-white ease-in-out duration-300'>Set</button>
                      {sendEmailsTo&&
                        <>
                          <SendEmailsTo onClose={toggleSendEmailsTo} settings={settings} setSettings={setSettings}/>
                          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
                        </>
                      }
                </div>
                <div className='flex items-center mt-4 justify-between'>
                   <span className="">Show Share Buttons</span>
                       <input
                         type="checkbox"
                         id="showShareButtons"
                         checked={showShareButtons}
                         onChange={toggleShowShareButtons}
                         className="sr-only"
                       />

                       <label htmlFor="showShareButtons" className="cursor-pointer -mr-2">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${showShareButtons ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${showShareButtons ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${showShareButtons ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                </div>
                <div className='mt-4'>
                      <label htmlFor="title">Loading Text</label>
                      <input className="border border-[#EBECF0] w-full px-2 py-3 rounded-[0.5rem]" name="title" value={settings?.loadingText} onChange={(e)=>setSettings({...settings, loadingText: e.target.value})}/>
                    </div>
                <div className='flex justify-between my-4'>
                      <p>Language</p>
                      <button onClick={toggleLanguage}  className='bg-[#EBECF0] px-3 py-1 rounded-[0.25rem] hover:bg-[#1E78C8] hover:text-white ease-in-out duration-300'>Set</button>
                      {language&&
                        <>
                          <Language onClose={toggleLanguage} leadCapture={leadCapture} setLeadCapture={setLeadCapture}/>
                          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
                        </>
                      }
                </div>
                <div className='flex items-center mt-4 justify-between'>
                   <span className="">Published</span>
                       <input
                         type="checkbox"
                         id="published"
                         checked={published}
                         onChange={(togglePublished)}
                         className="sr-only"
                       />

                       <label htmlFor="published" className="cursor-pointer -mr-2">
                         <div className="relative w-12 h-6">
                           <div className={`w-10 h-5 rounded-full shadow-inner ${published ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-5 h-5 bg-white border-2 ${published ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${published ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                </div>
              </div>
            )}
            <div className='flex justify-between mx-6 my-6'>
                <p className={`cursor-pointer ${dropdownStates.isANOpen&& 'font-bold'}`}>Analytics</p>
                <p onClick={() => toggleDropdown('isANOpen')} className={`cursor-pointer rounded-full ${dropdownStates.isANOpen? "bg-[#EBECF0]":""} duration-300 h-[30px] p-1`}>{!dropdownStates.isANOpen ? (<span className="material-symbols-outlined transition-all duration-300">expand_more</span>): (<span className="material-symbols-outlined transition-all duration-300">expand_less</span>)}</p>
            </div>
            {dropdownStates.isANOpen&&(
               <div className='text-left flex-col mx-6'>
                <div className='my-3'>
                  <label htmlFor="bt2text">FaceBook Pixel ID</label>
                  <input className="border border-[#EBECF0] w-full px-2 py-3 mt-1 rounded-[0.5rem] " name="bt2text" type='text'  value={analytics?.fbPixelId} onChange={(e)=>setAnalytics({...analytics, fbPixelId: e.target.value})}/>
                </div>
                <div className='my-3'>
                  <label htmlFor="bt2text">Google Analytics ID</label>
                  <input className="border border-[#EBECF0] w-full px-2 py-3 mt-1 rounded-[0.5rem] " name="bt2text" type='text'  value={analytics?.gAnalyticsId} onChange={(e)=>setAnalytics({...analytics, gAnalyticsId: e.target.value})}/>
                </div>
                <div className='my-3'>
                  <label htmlFor="bt2text">Google Tag Manager ID</label>
                  <input className="border border-[#EBECF0] w-full px-2 py-3 mt-1 rounded-[0.5rem] " name="bt2text" type='text'  value={analytics?.gTagManagerId} onChange={(e)=>setAnalytics({...analytics, gTagManagerId: e.target.value})}/>
                </div>
               </div>
            )}
            <div className='flex justify-between mx-6 my-6'>
                <p className={`cursor-pointer ${dropdownStates.isAUOpen&& 'font-bold'}`}>Automation</p>
                <p onClick={() => toggleDropdown('isAUOpen')} className={`cursor-pointer rounded-full ${dropdownStates.isAUOpen? "bg-[#EBECF0]":""} duration-300 h-[30px] p-1`}>{!dropdownStates.isAUOpen ? (<span className="material-symbols-outlined transition-all duration-300">expand_more</span>): (<span className="material-symbols-outlined transition-all duration-300">expand_less</span>)}</p>
            </div>
            {dropdownStates.isAUOpen&&(
               <div className='text-left flex-col mx-6 mb-5'>
                <div className='my-3'>
                  <label htmlFor="bt2text">Google Sheet URL</label>
                  <p className='text-[#334564BF] text-[13px] mt-1'>💡 Leads captured will be saved here</p>
                  <p className='text-[#334564BF] text-[13px]'>💡 Ensure the sheets are publicly accessible</p>
                  <input className="border border-[#EBECF0] mt-1 w-full px-2 py-3 rounded-[0.5rem] " type='text'  value={automation?.gSheetUrl} onChange={(e)=>setAutomation({...automation, gSheetUrl: e.target.value})}/>
                </div>
                <div className='my-3'>
                  <label htmlFor="bt2text">Google Sheet Name</label>
                  <input className="border border-[#EBECF0] mt-1 w-full px-2 py-3 rounded-[0.5rem] " type='text'  value={automation?.gSheetName} onChange={(e)=>setAutomation({...automation, gSheetName: e.target.value})}/>
                </div>
                <div className='my-3'>
                  <label htmlFor="bt2text">Pebbly Webhook URL</label>
                  <p className='text-[#334564BF] text-[13px]'>💡 Trigger After a Lead is captured</p>
                  <input className="border border-[#EBECF0] mt-1 w-full px-2 py-3 rounded-[0.5rem] " type='text'  value={automation?.pebWebhookUrl} onChange={(e)=>setAutomation({...automation, pebWebhookUrl: e.target.value})}/>
                </div>
               </div>
            )}
          </div>
           
        </div>
    );
}

export default Sidebar;