import React, {useEffect, useState} from 'react';
import Spinner from "./spinner"
import { useNavigate } from 'react-router-dom';
import ConfirmDelete from './confirmDelete';
import MoveProject from './moveProject';
import RenameProject from './renameProject';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Row = ({ type, nextType, data, handleTypeChange, setSelectedItem, selectedItem, groups, projects, reOrder, changeOrder }) => {
    const navigate = useNavigate();
    const [filteredData, setFilteredData] = useState(data.filter(item => {
        if (type === "Group") {
            return item.projectId === selectedItem?.id;
        } else if (type === "Interactive Book") {
            return item.groupId === selectedItem?.id;
        }
        // For "Project" type, include all items
        return true;
    }))
    const [deleteOpen, setDeleteOpen] = useState(Array(filteredData.length).fill(false));
    const [renameOpen, setRenameOpen] = useState(Array(filteredData.length).fill(false));
    const [moveOpen, setMoveOpen] = useState(Array(filteredData.length).fill(false));
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    

    const [orderedData, setOrderedData] = useState(filteredData); 
    const currentItems = orderedData.slice(indexOfFirstItem, indexOfLastItem);
    // Function to handle row reordering
    const handleDragEnd = (result) => {
        if (!result.destination) return; // If dropped outside the list, do nothing

        const newData = Array.from(orderedData);
        const [removed] = newData.splice(result.source.index, 1);
        newData.splice(result.destination.index, 0, removed);

        setOrderedData(newData); 
    };
    useEffect(()=>{
        if(changeOrder===true){

        }else if(changeOrder===false){
            if(type==="Project"){
                setOrderedData(projects)
            }else{
                setOrderedData(filteredData)
            }
        }
    }, [changeOrder])
    useEffect(()=>{
        setOrderedData(data.filter(item => {
            if (type === "Group") {
                return item.projectId === selectedItem?.id;
            } else if (type === "Interactive Book") {
                return item.groupId === selectedItem?.id;
            }
            // For "Project" type, include all items
            return true;
        }))
    }, [selectedItem, type])
    const toggleDeleteOpen = (index) => {
        const updatedDeleteOpen = [...deleteOpen];
        updatedDeleteOpen[index] = !updatedDeleteOpen[index];
        setDeleteOpen(updatedDeleteOpen);
    };

    const toggleRenameOpen = (index) => {
        const updatedRenameOpen = [...renameOpen];
        updatedRenameOpen[index] = !updatedRenameOpen[index];
        setRenameOpen(updatedRenameOpen);
    };

    const toggleMoveOpen = (index) => {
        const updatedMoveOpen = [...moveOpen];
        updatedMoveOpen[index] = !updatedMoveOpen[index];
        setMoveOpen(updatedMoveOpen);
    };

    const renderDraggableTableRow = (item, index) => {
        
       

        return (
            <Draggable key={item.id} draggableId={item.id} index={index}>
            {(provided) => (
                <tr
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className='overflow-hidden max-w-full'
                >
                    <td className='w-[10%] text-left flex'><span class="material-symbols-outlined"> drag_pan </span><span>{index + 1}</span></td>
                    <td className='w-1/4'>{type === "Group" ? item.groupName : (type === "Interactive Book" ? item.flipBookData.titleDetails.title : item.projectName)}</td>
                    {type !== "Interactive Book" && <td>{type === "Group" ? item?.flipbooks?.length : item?.groups?.length}</td>}
                    <td className='flex items-center w-full justify-center'>
                        {`Drag & Drop Enabled, Drag rows up & down`.toUpperCase()}
                    </td>
                </tr>
            )}
        </Draggable>
        );
    };
    const renderSimpleTableRow = (item, index) => {
        const handleClick = () => {
            handleTypeChange();
            setSelectedItem(item);
        };

        const handleEdit = () => {
            if (type === "Interactive Book") {
                if(item?.flipBookUrl!==item?.flipBookData.titleDetails.url)
                    navigate(`/edit/${encodeURIComponent(item?.customFlipBookUrl)}`);
                else
                    navigate(`/edit/${encodeURIComponent(item?.flipBookUrl)}`);
            }
        };

        return (
            
            
                <tr className='overflow-hidden max-w-full'>
                    <td className='w-[10%] text-left'>{index + 1}</td>
                    <td className='w-1/4'>{type === "Group" ? item.groupName : (type === "Interactive Book" ? item.flipBookData.titleDetails.title : item.projectName)}</td>
                    {type !== "Interactive Book" && <td>{type === "Group" ? item?.flipbooks?.length : item?.groups?.length}</td>}
                    <td className='flex items-center w-full justify-end'>
                        {type !== "Interactive Book" &&
                            <button className='bg-[#8392AB] rounded-10px text-white px-4 py-1 mx-1 rounded-[5px] whitespace-nowrap' onClick={handleClick}>
                                GO TO {nextType === "Interactive Book" ? "BOOK" : nextType.toUpperCase()}S
                            </button>
                        }
                        <button className='bg-[#8392AB] rounded-10px text-white px-4 py-1 mx-1 rounded-[5px]' onClick={handleEdit}>
                            {type === "Interactive Book" ? "EDIT" : "DUPLICATE"}
                        </button>
                        {type !== "Project" && (
                            <>
                                <button className='bg-[#8392AB] rounded-10px text-white px-4 py-1 mx-1 rounded-[5px]' onClick={() => toggleMoveOpen(index)}>
                                    MOVE
                                </button>
                                {moveOpen[index] && (
                                    <>
                                        <MoveProject onClose={() => toggleMoveOpen(index)} currentType={type} item={item} projects={projects} groups={groups} selectedItem={selectedItem}/>
                                        <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
                                    </>
                                )}
                            </>
                        )}
                        <button className='bg-[#8392AB] rounded-10px text-white px-4 py-1 mx-1 rounded-[5px]' onClick={() => toggleRenameOpen(index)}>
                            RENAME
                        </button>
                        {renameOpen[index] && (
                            <>
                                <RenameProject onClose={() => toggleRenameOpen(index)} currentType={type} item={item} />
                                <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
                            </>
                        )}
                        {type === "Interactive Book" &&
                            <button className='bg-[#8392AB] rounded-10px text-white px-4 py-1 mx-1 rounded-[5px]'>
                                {item?.settings?.published? "PUBLISHED": "PUBLISH"}
                            </button>
                        }
                        <button className='bg-[#EA0606] rounded-10px text-white px-4 py-1 mx-1 rounded-[5px]' onClick={() => toggleDeleteOpen(index)}>
                            <span className="material-symbols-outlined text-base"> delete </span>
                        </button>
                        {deleteOpen[index] && (
                            <>
                                <ConfirmDelete onClose={() => toggleDeleteOpen(index)} currentType={type} item={item} />
                                <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
                            </>
                        )}
                    </td>
                </tr>
           
       
        );
    };

    const onNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const onFirstPage = () => {
        if (currentPage > 1) {
            setCurrentPage(1);
        }
    };
    const onLastPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(totalPages);
        }
    };

    const onPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleItemsPerPageChange = (e) => {
        const newItemsPerPage = parseInt(e.target.value);
        if (!isNaN(newItemsPerPage) && newItemsPerPage > 0) {
            setItemsPerPage(newItemsPerPage);
            setCurrentPage(1); // Reset to first page when changing items per page
        }
    };
    
    
    return (
        <div className='h-full flex flex-col text-left m-4 mr-8 '>
            <p className='font-bold mt-3'>My {type!="Interactive Book" && 'Interactive Book'} {type}s</p>
            <DragDropContext onDragEnd={handleDragEnd}>
                {reOrder && (

                        <Droppable droppableId="droppable">
                            {(provided) => (
                             
                                <table
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className='text-sm mt-4 border-separate border-spacing-y-5 overflow-auto max-w-full'
                                >
                                {currentItems.length > 0 ? (
                                    <>
                                        <thead className='text-gray-400 '>
                                            <th>Order</th>
                                            <th>{type} Name</th>
                                            {type !== "Interactive Book" && <th>{nextType}s included</th>}
                                            <th></th>
                                        </thead>
                                        <tbody className='overflow-hidden'>
                                            {currentItems.map((item, index) => renderDraggableTableRow(item, index))}
                                        </tbody>
                                        {provided.placeholder}
                                    </>
                                ):(
                                    <div className='grid w-full h-full text-center'>
                                        <p>No {type}s found!</p>
                                    </div>
                                )}
                                </table>
                            )}
                        </Droppable>

                )}
            </DragDropContext>
            {!reOrder && (
                <table className='text-sm mt-4 border-separate border-spacing-y-5 overflow-auto max-w-full'>
                    {currentItems.length > 0 ? (
                        <>
                            <thead className='text-gray-400 '>
                                <th>Order</th>
                                <th>{type} Name</th>
                                {type !== "Interactive Book" && <th>{nextType}s included</th>}
                                <th></th>
                            </thead>
                            <tbody className='overflow-hidden'>
                                {currentItems.map((item, index) => renderSimpleTableRow(item, index))}
                            </tbody>
                        </>
                    ) : (
                        <div className='grid w-full h-full text-center'>
                            <p>No {type}s found!</p>
                        </div>
                    )}
                </table>
            )}
            <div className='flex text-sm absolute left-5 bottom-4'>
                <p className=' mr-2'>{type}s per page </p>
                <input type="text" defaultValue={itemsPerPage} 
                
                    onChange={(e) => {
                       
                        handleItemsPerPageChange(e)
                    }} 
                    className='w-7 h-6 p-2 border border-gray-400 rounded-[3px]' 
                />                
            </div>
            {/* Buttons for changing pages */}
            <div className='absolute left-[45%] bottom-4 flex text-sm'>
                        <p onClick={onFirstPage} className='cursor-pointer'><span class="material-symbols-outlined"> keyboard_double_arrow_left </span> &nbsp;</p>
                        <p onClick={onPreviousPage} className='cursor-pointer'><span class="material-symbols-outlined"> chevron_left </span> &nbsp;</p>
                        <p>Page {currentPage} of {totalPages}&nbsp;</p>
                        <p onClick={onNextPage} className='cursor-pointer'><span class="material-symbols-outlined"> chevron_right </span></p>
                        <p onClick={onLastPage} className='cursor-pointer'><span class="material-symbols-outlined"> keyboard_double_arrow_right </span></p>
                </div>
        </div>
    );
}

export default Row;
