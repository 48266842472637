import React, { useState, useEffect } from 'react';
import { login } from '../api';
import Loader from '../common/loader';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const [rememberMe, setRememberMe] = useState(false)
  const [inputValues, setInputValues] = useState()
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState()
  const navigate = useNavigate()
  const onChange = (e) => {
    setResponse(undefined)
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value
    })
  }
  const onLogin = async(e) =>{
      e.preventDefault()
      setLoading(true)
      try{
        const {username, password} = inputValues
        
        const response = await login({username: username, password: password})
       
        if(response?.error){
          setResponse(response)
        }else{
          localStorage.setItem("user", JSON.stringify(response.user));
          localStorage.setItem("access", JSON.stringify(response.access));
          localStorage.setItem("refresh", JSON.stringify(response.refresh));
          navigate('/dashboard')
        }
        setLoading(false)
      }catch(error){
        console.error("ERROR", error)
        setLoading(false)
      }
  } 
  useEffect(()=>{
    if(localStorage.getItem("user")){
        navigate('/dashboard')
    }
  }, [navigate])  

  const toggleRememberMe = () =>{
    setRememberMe(!rememberMe)
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-white bg-center">
      <div className="bg-white rounded-lg w-full lg:w-[40%] flex flex-col">
        <div className="flex items-center justify-center mb-20 -mt-8">
            <img src="/logo.png" alt="Logo" className="h-12 w-auto" />
            <h2 className="ml-2 text-[18px] lg:text-xl text-[#1E78C8] font-semibold">Urban Entrepreneur Universe</h2>
        </div>
        <div className='mx-8 lg:mx-auto text-left'>
          
          <p className="text-lg text-[#334564] mb-4 font-bold">Sign in</p>
          <p className="text-sm text-[#334564] mb-4">Enter Your Username & Password To Access Your Account.</p>
          <form className='text-left' onSubmit={onLogin}> 
            <div className="mb-4">
              <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
              <input type="username" id="username" name="username" placeholder='Username' onChange={onChange} className="mt-1 text-sm p-3 block w-full rounded-md border border-gray-300 focus:outline-none focus:ring-[#1E78C8] focus:border-[#1E78C8]" />
            </div>
            <div className="mb-2">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
              <input type="password" id="password" name="password" placeholder='Password' onChange={onChange} className="mt-1 text-sm p-3 block w-full rounded-md border border-gray-300 focus:outline-none focus:ring-[#1E78C8] focus:border-[#1E78C8]" />
            </div>
            <div className="flex items-center justify-between mb-8">
              <div className='flex items-center justify-between text-sm'>
                  
                       <input
                         type="checkbox"
                         id="rememberMe"
                         checked={rememberMe}
                         onChange={toggleRememberMe}
                         className="sr-only"
                       />

                       <label htmlFor="rememberMe" className="cursor-pointer">
                         <div className="relative w-10 h-4">
                           <div className={`w-12 h-6 rounded-full shadow-inner ${rememberMe ? 'bg-[#1E78C8]' : 'bg-[#EBECF0]'}`}></div>
                           <div className={`absolute left-0 top-0 w-6 h-6 bg-white border-2 ${rememberMe ? 'border-[#1E78C8]' : 'border-[#EBECF0]'} rounded-full transition-transform duration-300 transform ${rememberMe ? 'translate-x-full' : 'translate-x-0'}`}></div>
                         </div>
                   </label>
                   <span className="ml-4 mt-2">Remember me</span>
              </div>
              <div className="text-sm">
                <a href="https://ueu-dashboards.com/accounts/reset/basic-reset/" target='_blank' className="font-medium text-[#1E78C8] hover:text-[#1E78C8]">Forgot password?</a>
              </div>

            </div>
            <div className='w-full'>
                {response?.error&&<p className='text-red-600 font-bold text-sm w-full text-center my-1'>{response?.error}</p>}
                <button type="submit" className="w-full py-2 px-4 bg-[#1E78C8] font-bold text-white rounded-md border-2 border-[#1E78C8] hover:bg-white hover:text-[#1E78C8] transition duration-300 ease-in-out">{loading? <Loader/> :'SIGN IN'}</button>
            </div>
          </form>
          <div className='w-full text-center'>
            <p className="mt-4 text-sm text-[#1E78C8/50%]">Don't have an account? <a target='_blank' href="https://ueu-dashboards.com/accounts/register/basic-register/" className="font-medium text-[#1E78C8] hover:text-[#1E78C8]">Sign up</a></p>
          </div>
        </div>
      </div>
      <div className='min-h-screen relative bg-cover bg-center w-[60%] text-white hidden lg:block' style={{backgroundImage: `url('https://s3-alpha-sig.figma.com/img/2183/2d8d/fbefdf61276fb76590eb7ddaf2bf73e7?Expires=1711324800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=jz0GQiR5v04zet9SvBKVGr6mVmXdI96VUr5eZWy3N-wPGha4dtjYJZ821dYLrcbXPmuPvYh-mnf0JFsMnsGlNcCBnojVn-sz3toiCLywEYj4csTCOYdYbA9M-Dluhe-tE9N1EVZMPZ2bH6GHEuGtNjABkYVF5CaXXE0KxAuBKRQ44QTT3bDeLfQbOzv1tpOlkgP6tmgKNfJ5ImPjBrx~NUWeTs2976BSrJkw8MLgOuU4LJGjc6PoyCMOS0j6lIEmbYZmhHV2e67ZYW5cL9~KYiFPkqUBoTr6gaUoVvOnxAM50v4Xx8II6z9cmy-fagl3PFePWtgyzF8tunClhL9LHg__')`}}>
      <div className='absolute left-80 top-40 bg-[#1E78C8] w-40 h-40 filter blur-[80px]'></div>
      <div className='absolute left-[150px] top-[70%] bg-[#1E78C8] w-60 h-60 filter blur-[90px]'></div>
      <div className='absolute left-[450px] top-[70%] bg-[#1E78C8] w-60 h-60 filter blur-[90px]'></div>
        <div className='absolute top-[75%] w-full poppins'>
          
          <h2 className='font-[700] text-[28px]'>Unleash your teaching skills</h2>
          <p>Effortlessly organize your teaching materials with a seamless experience</p>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
