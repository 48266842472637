import React, { useState } from 'react';
import Loader from './loader';
import NewProject from '../components/newProject';

const Bar = ({title, bt1, bt2, onCreate, loading, last, projects, groups, selectedItem,reOrder, setReOrder, setSelectedItem, pSelectedItem, setPSelectedItem, changeOrder, setChangeOrder}) => {
    const [isOpen, setOpen] = useState()
    const toggleOpen = () =>{
        setOpen(!isOpen)
    }
    return (
        <div className='w-full overflow-auto'>
            <div className='flex justify-between w-full bg-white p-4 rounded-[10px] mt-4 mb-5 shadow-md overflow-auto'>
                    {title==="Project"?<p className='mt-2'>{projects?.length} {title}s</p>: (
                        <>
                        <div className='mt-0'>
                            <p>Current {last} {last==="Group"?<select className='border-2 border-gray-400 rounded-[5px] ml-2 px-8 text-left py-1.5' defaultValue={selectedItem?.id} 
                                onChange={(e) => {
                                   
                                    setSelectedItem(groups.find(group => group.id === e.target.value))
                                }}>
                                {groups.filter(group=>group?.projectId===pSelectedItem?.id)?.map((item)=><option value={item.id}>{item?.groupName}</option>)}</select>
                                :
                                
                            <select className='border-2 border-gray-400 rounded-[5px] ml-2 px-8 text-left py-1.5' 
                            defaultValue={selectedItem?.id} 
                            onChange={(e) =>{ 
                                setSelectedItem(projects.find(project => project.id === e.target.value))
                                setPSelectedItem(projects.find(project => project.id === e.target.value))
                            }}>{projects?.map((item)=><option value={item.id}>{item?.projectName}</option>)}</select>}</p>
                        </div>
                        </>
                    )}
                    {reOrder?(
                        <div className='flex w-1/2 items-end justify-end text-center overflow-x-auto'>
                            <button className='flex px-2 w-[35%] text-center py-2 bg-gray-400 border-2 border-gray-400 text-white rounded-[10px] hover:shadow-md hover:text-gray-400 hover:bg-white ease-in-out duration-300' onClick={(e)=> {setReOrder(!reOrder); setChangeOrder(false)}}><p className='w-full text-center'>CANCEL</p></button>
                            <button className='flex w-[35%] text-center px-2 py-2 bg-[#1E78C8] border-2 border-[#1E78C8] text-white rounded-[10px] hover:shadow-md hover:text-[#1E78C8] hover:bg-white ease-in-out duration-300 mx-2' onClick={(e)=> {setReOrder(!reOrder); setChangeOrder(true)}}>{loading? <Loader/>: <p className='text-center w-full'>SAVE</p>}</button>
                        </div>
                    ):(
                        <div className='flex w-1/2 items-end justify-end overflow-x-auto'>
                            <button className='flex px-2 py-2 bg-[#1E78C8] border-2 border-[#1E78C8] text-white rounded-[10px] hover:shadow-md hover:text-[#1E78C8] hover:bg-white ease-in-out duration-300' onClick={(e)=> setReOrder(!reOrder)}><span class="material-symbols-outlined"> screenshot_region </span>{bt1}</button>
                            <button className='flex mx-w-mx px-2 py-2 bg-[#1E78C8] border-2 border-[#1E78C8] text-white rounded-[10px] hover:shadow-md hover:text-[#1E78C8] hover:bg-white ease-in-out duration-300 mx-2' onClick={toggleOpen}>{loading? <Loader/>: <p className='whitespace-nowrap flex'><span class="material-symbols-outlined"> add </span>{bt2}</p>}</button>
                        </div>
                    )}
            </div>
            {isOpen&&(
                <>
                    <NewProject onClose={toggleOpen} onCreate={onCreate} currentType={title}/>
                    <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
                </>
            )}
            
        </div>
    );
}

export default Bar;